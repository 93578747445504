import React from 'react';

class SponsorsPackage extends React.Component{
 
  constructor(prop){
    super(prop);
  }

  render(){
    if (this.props.language == "en") {
    if(this.props.tier === 1){
      return (
        <div className="sponsors-page-package">
            <img className="sponsors-page-package-image" src="https://i.imgur.com/9IS7v60.jpg"></img>
            <p className="sponsors-page-package-header">Bronze</p>
            <p className="sponsors-page-package-info">$200 a month or $2000 per year</p>
            <p className="sponsors-page-package-info">Package includes:</p>
            <p className="sponsors-page-package-info">Logo or business card visiblity</p>
            <p className="sponsors-page-package-info">Two (2) announcements per month</p>
        </div>
      );
    }else if(this.props.tier === 2){
      return (
        <div className="sponsors-page-package">
            <img className="sponsors-page-package-image" src="https://i.imgur.com/iWlifmm.jpg"></img>
            <p className="sponsors-page-package-header">Silver</p>
            <p className="sponsors-page-package-info">$400 a month or $4,000 per year</p>
            <p className="sponsors-page-package-info">Package includes:</p>
            <p className="sponsors-page-package-info">Logo or business card visiblity</p>
            <p className="sponsors-page-package-info">Up to four (4) announcements per month</p>
            <p className="sponsors-page-package-info">One (1) company-wide training session of a topic of your choice</p>
        </div>
      );
    }else if(this.props.tier === 3){
      return (
        <div className="sponsors-page-package">
            <img className="sponsors-page-package-image" src="https://i.imgur.com/aiVn4VK.jpg"></img>
            <p className="sponsors-page-package-header">Gold</p>
            <p className="sponsors-page-package-info">$650 a month or $7,000 per year</p>
            <p className="sponsors-page-package-info">Package includes:</p>
            <p className="sponsors-page-package-info">Logo or business card visiblity</p>
            <p className="sponsors-page-package-info">Up to ten (10) announcements permonth</p>
            <p className="sponsors-page-package-info">One (1) illustrated promotion per year to promote your business/organization</p>
            <p className="sponsors-page-package-info">VIP invitations provided to one (1) of our networking events per year</p>
            <p className="sponsors-page-package-info">One (1) company-wide training session of a topic of your choice</p>
            
        </div>
      );
    }else if(this.props.tier === 4){
      return (
        <div className="sponsors-page-package">
            <img className="sponsors-page-package-image" src="https://i.imgur.com/qvPnfNt.jpg"></img>
            <p className="sponsors-page-package-header">Platinum</p>
            <p className="sponsors-page-package-info">$1,000 a month or $10,500 per year</p>
            <p className="sponsors-page-package-info">Package includes:</p>
            <p className="sponsors-page-package-info">Logo or business card visiblity</p>
            <p className="sponsors-page-package-info">Up to twenty (20) announcements permonth</p>
            <p className="sponsors-page-package-info">Three (3) illustrated promotion per year to promote your business/organization</p>
            <p className="sponsors-page-package-info">VIP invitations provided to all of our networking events</p>
            <p className="sponsors-page-package-info">Use our platform to promote your company or organization’s programs and events</p>
            <p className="sponsors-page-package-info">Two (2) company-wide training session of a topic of your choice</p>
          
        </div>
      );
    }
  }else{
    if(this.props.tier === 1){
      return (
        <div className="sponsors-page-package">
            <img className="sponsors-page-package-image" src="https://i.imgur.com/9IS7v60.jpg"></img>
            <p className="sponsors-page-package-header">Bronze</p>
            <p className="sponsors-page-package-info">200 $ par mois ou 2 000 $ par an</p>
            <p className="sponsors-page-package-info">Service offert:</p>
            <p className="sponsors-page-package-info">Logo ou carte d’affaires,</p>
            <p className="sponsors-page-package-info">Deux (2) annonces au choix par mois</p>
        </div>
      );
    }else if(this.props.tier === 2){
      return (
        <div className="sponsors-page-package">
            <img className="sponsors-page-package-image" src="https://i.imgur.com/iWlifmm.jpg"></img>
            <p className="sponsors-page-package-header">Argent</p>
            <p className="sponsors-page-package-info">$400 a month or $4,000 per year</p>
            <p className="sponsors-page-package-info">Service offert:</p>
            <p className="sponsors-page-package-info">Logo ou carte d’affaires,</p>
            <p className="sponsors-page-package-info">Quatre (4) annonces au choix par mois,</p>
            <p className="sponsors-page-package-info">Une (1) session de formation avec un sujet de votre choix par année</p>
        </div>
      );
    }else if(this.props.tier === 3){
      return (
        <div className="sponsors-page-package">
            <img className="sponsors-page-package-image" src="https://i.imgur.com/aiVn4VK.jpg"></img>
            <p className="sponsors-page-package-header">Or</p>
            <p className="sponsors-page-package-info">650 $ par mois ou 7 000 $ par an</p>
            <p className="sponsors-page-package-info">Service offert:</p>
            <p className="sponsors-page-package-info">Logo ou carte d’affaires,</p>
            <p className="sponsors-page-package-info">Dix (10) annonces au choix par mois</p>
            <p className="sponsors-page-package-info">Une (1) illustration promotionnelle de votre entreprise/organisation par année,</p>
            <p className="sponsors-page-package-info">Des invitations VIP fournies à l'un de nos événements de réseautage par année,</p>
            <p className="sponsors-page-package-info">Une (1) session de formation avec un sujet de votre choix par année</p>
            
        </div>
      );
    }else if(this.props.tier === 4){
      return (
        <div className="sponsors-page-package">
            <img className="sponsors-page-package-image" src="https://i.imgur.com/qvPnfNt.jpg"></img>
            <p className="sponsors-page-package-header">Platine</p>
            <p className="sponsors-page-package-info">1 000 $ par mois ou 10 500 $ par an</p>
            <p className="sponsors-page-package-info">Service offert:</p>
            <p className="sponsors-page-package-info">Logo ou carte d’affaires,</p>
            <p className="sponsors-page-package-info">Vingt (20) annonces au choix par mois</p>
            <p className="sponsors-page-package-info">Trois (3) illustration promotionnelle de votre entreprise/organisation par année</p>
            <p className="sponsors-page-package-info">Des invitations VIP fournies pour tous nos événements de réseautage,</p>
            <p className="sponsors-page-package-info">Utilisation de notre plate-forme pour afficher des programmes et événements de votre entreprise/organisation</p>
            <p className="sponsors-page-package-info">Deux (2) sessions de formation avec un sujet de votre choix par année</p>
          
        </div>
      );
    }
  }
  }
}

export default SponsorsPackage;
