import React, { Component } from 'react';
import '../../scss/about_bio_pages/bio-page-com.scss'

class BioPage extends Component {
    state = {}
    componentDidMount() {
        document.getElementById('bio_page').scrollIntoView();
    }
    render() {
        return (
            <div className='bio_page_com'>
                <img src={this.props.image} />
                <div className='bio_page_com_text'>
                    <h1>{this.props.name}</h1>
                    <h3>{this.props.title}</h3>
                    {this.props.paragraphs.map(paragraph => <p>{paragraph}</p>) }
                </div>
            </div>
        );
    }
}

export default BioPage;