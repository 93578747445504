import React, { Component } from 'react';
import '../../scss/about_bio_pages/bio-com.scss';

class BioComponent extends Component {
    state = {}
    render() {
        const { isEnglish } = this.props;
        return (
            <div className='content'>
                <div className='top_content'>
                    <img src={this.props.image} />
                    <div className='title_name'>
                        <h2>{this.props.name}</h2>
                        <h3>{this.props.title}</h3>
                    </div>
                </div>
                <p>{this.props.para}</p>
                <div onClick={this.props.goToBios} className='see_more'>{isEnglish ? "See More" : "Voir plus"}</div>
            </div>
        );
    }
}

export default BioComponent;