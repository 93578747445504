import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import '../../scss/order-confirmation.scss';

function OrderConfirmation({ success }){
  const [isEnglish, setIsEnglish] = useState(true);

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('language')) {
      if (searchParams.get('language') == 'en') {
        setIsEnglish(true);
      } else {
        setIsEnglish(false)
      }
    }
  }, []);

  return (
    <div className="order-confirmation">
      <Card className="message-container">
        <Card.Body>
          {
            success ?
              isEnglish ?
                <p>
                  Thank you for your order! <br/>
                  The instructions on how to access your eBook has been sent to your email. <br/>
                  If you have any questions, please contact us at <a href="mail:client.team@boxia.ca">client.team@boxia.ca</a>
                </p>
              :
                <p>
                  Merci pour votre commande! <br/>
                  Les instructions sur comment accéder à votre eBook ont été envoyées à votre courriel. <br/>
                  Si vous avez des questions, n'hésitez pas de nous contacter à <a href="mail:client.team@boxia.ca">client.team@boxia.ca</a>
                </p>
            :
              isEnglish ?
                <p>
                  Sorry, we weren't able to process your order at the moment. <br/> 
                  Please contact us at <a href="mail:client.team@boxia.ca">client.team@boxia.ca</a> for assistance.
                </p>
              :
                <p>
                  Désolé de n'avoir pas pu traiter votre commande en ce moment.  <br/> 
                  Prière de nous contacter à <a href="mail:client.team@boxia.ca">client.team@boxia.ca</a> pour obtenir de l'aide.
                </p>
          }
        </Card.Body>
      </Card>
    </div>
  );
}

export default OrderConfirmation;
