import React from "react";
import "../scss/tools.scss";
import PageHeader from "./PageHeader";
import PageHeaderMobile from "./PageHeaderMobile";
import ToolSquare from "./ToolSquare";
import MediaQuery from "react-responsive";

class CanvaPresentation extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 0,
          paddingTop: "56.2500%",
          paddingBottom: 0,
          boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
          marginTop: "1.6em",
          marginBottom: "0.9em",
          overflow: "hidden",
          borderRadius: "8px",
          willChange: "transform",
        }}
      >
        <iframe
          loading="lazy"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            border: "none",
            padding: 0,
            margin: 0,
          }}
          src="https://www.canva.com/design/DAFgVXIuL1s/view?embed"
          allowFullScreen="allowfullscreen"
          allow="fullscreen"
        />
      </div>
    );
  }
}

class ToolsPage extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    let isEnglish = true;
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("language")) {
      if (searchParams.get("language") == "en") {
        isEnglish = true;
      } else {
        isEnglish = false;
      }
    } else {
      window.location.search = "language=en";
    }

    if (isEnglish) {
      return (
        <div>
          <MediaQuery query="(max-device-width: 1179px)">
            <div className="tools-page-mobile">
              <PageHeaderMobile
                image="https://i.imgur.com/ZtdX3SD.png"
                text="Downloadable Tools"
              />
              <CanvaPresentation />
              <div className="tools-page-squares-container">
                <ToolSquare
                  img="https://i.imgur.com/DZtIBlt.png"
                  link="https://laurier175.ca/documents/Laurier-bd-en.pdf"
                  title=""
                  text=""
                  download="Download"
                />
                <ToolSquare
                  img="https://i.imgur.com/3vmcFOw.png"
                  link="https://laurier175.ca/documents/Laurier-guide-en.pdf"
                  title=""
                  text=""
                  download="Download"
                />
              </div>
            </div>
          </MediaQuery>

          <MediaQuery query="(min-device-width: 1179px)">
            <div className="tools-page">
              <PageHeader
                image="https://i.imgur.com/ZtdX3SD.png"
                text="Downloadable Tools"
              />
              <CanvaPresentation />
              <div className="tools-page-squares-container">
                <ToolSquare
                  img="https://i.imgur.com/DZtIBlt.png"
                  link="https://laurier175.ca/documents/Laurier-bd-en.pdf"
                  title=""
                  text=""
                  download="Download"
                />
                <ToolSquare
                  img="https://i.imgur.com/3vmcFOw.png"
                  link="https://laurier175.ca/documents/Laurier-guide-en.pdf"
                  title=""
                  text=""
                  download="Download"
                />
              </div>
            </div>
          </MediaQuery>
        </div>
      );
    } else {
      return (
        <div>
          <MediaQuery query="(max-device-width: 1179px)">
            <div className="tools-page-mobile">
              <PageHeaderMobile
                image="https://i.imgur.com/ZtdX3SD.png"
                text="Outils Téléchargeables"
              />
              <CanvaPresentation />
              <div className="tools-page-squares-container">
                <ToolSquare
                  img="https://i.imgur.com/4mJrErP.png"
                  link="https://laurier175.ca/documents/Laurier-bd-fr.pdf"
                  title=""
                  text=""
                  download="Télécharger"
                />
                <ToolSquare
                  img="https://i.imgur.com/YBDYFzS.png"
                  link="https://laurier175.ca/documents/Laurier-guide-fr.pdf"
                  title=""
                  text=""
                  download="Télécharger"
                />
              </div>
            </div>
          </MediaQuery>
          <MediaQuery query="(min-device-width: 1179px)">
            <div className="tools-page">
              <PageHeader
                image="https://i.imgur.com/ZtdX3SD.png"
                text="Outils Téléchargeables"
              />
              <CanvaPresentation />
              <div className="tools-page-squares-container">
                <ToolSquare
                  img="https://i.imgur.com/4mJrErP.png"
                  link="https://laurier175.ca/documents/Laurier-bd-fr.pdf"
                  title=""
                  text=""
                  download="Télécharger"
                />
                <ToolSquare
                  img="https://i.imgur.com/YBDYFzS.png"
                  link="https://laurier175.ca/documents/Laurier-guide-fr.pdf"
                  title=""
                  text=""
                  download="Télécharger"
                />
              </div>
            </div>
          </MediaQuery>
        </div>
      );
    }
  }
}

export default ToolsPage;
