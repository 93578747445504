import React, { Component } from "react";
import "../scss/contact-us-page.scss";
import axios from "axios";
import { getApiUrl } from '../Util';

class ContactUsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactUs: " ",
      yourName: " ",
      enterYourName: " ",
      email: " ",
      enterYourEmail: " ",
      phone: " ",
      enterYourPhoneNumber: " ",
      message: " ",
      yourMessageHere: " ",
      isEnglish: true,

      // Contact form
      allowSubmission: false,
      contactFormName: "",
      contactFormEmail: "",
      contactFormPhone: "",
      contactFormMessage: "",
      contactFormSubmitting: false,
      successSubmission: null
    };

    this.submitOnClick = this.submitOnClick.bind(this);
  }

  componentDidMount() {
    let isEnglish = true;
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("language")) {
      if (searchParams.get("language") === "en") {
        isEnglish = true;
      } else {
        isEnglish = false;
      }
    } else {
      window.location.search = "language=en";
    }
    
    if (isEnglish) {
      this.setState({
        contactUs: "Contact Us",
        yourName: "Your Name",
        enterYourName: "Enter Your Name",
        email: "Email",
        enterYourEmail: "Enter Your Email",
        phone: "Phone",
        enterYourPhoneNumber: "Enter Your Phone Number",
        message: "Message",
        yourMessageHere: "Your Message Here...",
        submit: "Submit",
        submitting: "Submitting...",
        successSubmissionMessage:
          "Thanks for reaching out, we will contact you shortly!",
        unsuccessSubmissionMessage:
          "We are experiencing some technical problems at the moment, please try again later."
      });
    } else {
      this.setState({
        contactUs: "Contactez Nous",
        yourName: "Nom",
        enterYourName: "Écrivez votre nom",
        email: "Adresse courriel",
        enterYourEmail: "Écrivez votre adresse courriel",
        phone: "Numéro de téléphone",
        enterYourPhoneNumber: "Écrivez votre numéro de téléphone",
        message: "Message",
        yourMessageHere: "Écrivez votre message",
        submit: "Soumettre",
        submitting: "Soumission...",
        successSubmissionMessage:
          "Merci de nous avoir contacté, nous communiquerons avec vous sous peu.",
        unsuccessSubmissionMessage:
          "Nous avons des problèmes techniques en ce moment, veuillez essayer de nouveau plus tard."
      });
    }
    this.setState({
      isEnglish: isEnglish
    });
  }

  checkIsReadyForSubmission() {
    const {
      contactFormEmail,
      contactFormMessage,
      contactFormName,
      contactFormPhone
    } = this.state;

    if (
      contactFormEmail !== "" &&
      contactFormMessage !== "" &&
      contactFormName !== "" &&
      contactFormPhone !== ""
    ) {
      this.setState({
        allowSubmission: true
      });
    }
  }

  submitOnClick() {
    const {
      contactFormEmail,
      contactFormMessage,
      contactFormName,
      contactFormPhone
    } = this.state;

    this.setState({
      contactFormSubmitting: true
    });

    axios
      .post(`${getApiUrl()}/contact_us`, {
        sender_name: contactFormName,
        sender_email: contactFormEmail,
        phone_number: contactFormPhone,
        message: contactFormMessage
      })
      .then(response => {
        this.setState({
          contactFormSubmitting: false,
          successSubmission: true
        });
      })
      .catch(error => {
        this.setState({
          contactFormSubmitting: false,
          successSubmission: false
        });
      });
  }

  render() {
    const { state } = this;
    return (
      <div className="contact_page">
        <div className="contact_page_content">
          <div className="title">
            <div className="dark_filter">
              <h1>{state.contactUs}</h1>
            </div>
          </div>

          <div className="contact_form_section">
            <div className="contact_form">

              <div className="basic_information">

                <div className="basic_input first_input">
                  <h3>{state.yourName}</h3>
                  <input
                    type="text"
                    placeholder={state.enterYourName}
                    onChange={e =>
                      this.setState({ contactFormName: e.target.value }) ||
                      this.checkIsReadyForSubmission()
                    }
                  />
                </div>
                <div className="basic_input middle_input">
                  <h3>{state.email}</h3>
                  <input
                    type="email"
                    placeholder={state.enterYourEmail}
                    onChange={e =>
                      this.setState({ contactFormEmail: e.target.value }) ||
                      this.checkIsReadyForSubmission()
                    }
                  />
                </div>


                <div className="basic_input last_input">
                  <h3>{state.phone}</h3>
                  <input
                    type="number"
                    placeholder={state.enterYourPhoneNumber}
                    onChange={e =>
                      this.setState({ contactFormPhone: e.target.value }) ||
                      this.checkIsReadyForSubmission()
                    }
                  />
                </div>

              </div>

              <div className="message">
                <h3>{state.message}</h3>
                <textarea
                  type="text"
                  placeholder={state.yourMessageHere}
                  onChange={e =>
                    this.setState({
                      contactFormMessage: e.target.value.replace(
                        /\r?\n/g,
                        "<br />"
                      )
                    }) || this.checkIsReadyForSubmission()
                  }
                />
              </div>

              <div className="submit">
                <div
                  className={`submit_text ${!state.allowSubmission && "disable"}`}
                  onClick={() => state.allowSubmission && this.submitOnClick()}
                >
                  {state.contactFormSubmitting
                    ? state.submitting
                    : state.submit}
                </div>
                {state.successSubmission !== null && (
                  
                  <span className="submission-message">
                    {state.successSubmission
                      ? state.successSubmissionMessage
                      : state.unsuccessSubmissionMessage}
                  </span>
                )}
              </div>



            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsPage;
