import React from 'react';
import '../scss/privacy-page.scss';
import PageHeader from './PageHeader';
import ToolSquare from './ToolSquare';

class PrivacyPage extends React.Component{
 
  constructor(prop){
    super(prop);
  }

  render(){
    return (
      <div className="privacy-page">
          <PageHeader image="https://res.cloudinary.com/twenty20/private_images/t_watermark-criss-cross-10/v1488311297000/photosp/9bc9de11-9a47-45e0-8ae8-dc6ce4880944/stock-photo-book-reading-beautiful-woman-red-nails-bookworm-bookshop-library-books-reading-book-people-reading-9bc9de11-9a47-45e0-8ae8-dc6ce4880944.jpg" text="Privacy Statement"/>
          <div className ="privacy-page-content">
            <p className="privacy-page-content-paragraph">
            BoXia Partage is committed to preserving your privacy. To better protect your privacy we provide this privacy statement (“Statement”) explaining our online information practices and the choices you can make about the way your information is collected and used. To make this Statement easy to find, we make it available on our homepage.
            This Statement applies to all information submitted on the website and/or social media, such as Facebook. Based on the activity you perform on the website and/or social media, we collect different types of information. 
            </p>
            <p>
            The types of Personal Information collected at these pages and forms are:
            </p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
            </ul>
            <p>
            By providing us with your information, you consent to us using it for the purposes set out in this Statement.
            We use the information at our disposal to send you communications, to inform you about our products and service offerings, conditions and regulations. We also use your information to respond to you when you contact us.
            </p>
            <br></br>
        </div>
      </div>
    );
  }
}

export default PrivacyPage;
