import React from "react";
import "../scss/services-page.scss";
import PageHeader from "./PageHeader";
import PageHeaderMobile from "./PageHeaderMobile";
import ServicesSquare from "./ServicesSquare";
import MediaQuery from "react-responsive";
import ServicesEnglishDesktop from "../img/services/BOXIA_SERVICES-EN-Desktop.png";
import ServicesEnglishMobile1 from "../img/services/BOXIA_SERVICES-EN-Mobile1.png";
import ServicesEnglishMobile2 from "../img/services/BOXIA_SERVICES-EN-Mobile2.png";
import ServicesEnglishMobile3 from "../img/services/BOXIA_SERVICES-EN-Mobile3.png";
import ServicesEnglishMobile4 from "../img/services/BOXIA_SERVICES-EN-Mobile4.png";

import ServicesFrenchDesktop from "../img/services/BOXIA_SERVICES-FR-Desktop.png";
import ServicesFrenchMobile1 from "../img/services/BOXIA_SERVICES-FR-Mobile1.png";
import ServicesFrenchMobile2 from "../img/services/BOXIA_SERVICES-FR-Mobile2.png";
import ServicesFrenchMobile3 from "../img/services/BOXIA_SERVICES-FR-Mobile3.png";
import ServicesFrenchMobile4 from "../img/services/BOXIA_SERVICES-FR-Mobile4.png";

class ServicesPage extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    let isEnglish = true;
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("language")) {
      if (searchParams.get("language") == "en") {
        isEnglish = true;
      } else {
        isEnglish = false;
      }
    } else {
      window.location.search = "language=en";
    }
    if (isEnglish) {
      return (
        <div>
          <MediaQuery query="(max-device-width: 764px)">
            <div className="services-page-mobile">
              <PageHeaderMobile
                image="https://i.imgur.com/dc1TYS9.png"
                text="Services"
              />
              <div className="services-mobile-img-container">
                <img
                  className="services-img-mobile"
                  src={ServicesEnglishMobile1}
                />
                <img
                  className="services-img-mobile"
                  src={ServicesEnglishMobile2}
                />
              </div>
              <div className="services-mobile-img-container">
                <img
                  className="services-img-mobile"
                  src={ServicesEnglishMobile3}
                />
                <img
                  className="services-img-mobile"
                  src={ServicesEnglishMobile4}
                />
              </div>
            </div>
          </MediaQuery>
          <MediaQuery query="(max-device-width: 1056px) and (min-device-width:765px)">
            <div className="services-page-mobile">
              <PageHeaderMobile
                image="https://i.imgur.com/dc1TYS9.png"
                text="Services"
              />
              <img
                className="services-img-desktop"
                src={ServicesEnglishDesktop}
              />
            </div>
          </MediaQuery>
          <MediaQuery query="(max-device-width: 1179px) and (min-device-width:1056px)">
            <div className="services-page-mobile">
              <PageHeaderMobile
                image="https://i.imgur.com/dc1TYS9.png"
                text="Services"
              />
              <img
                className="services-img-desktop"
                src={ServicesEnglishDesktop}
              />
            </div>
          </MediaQuery>
          <MediaQuery query="(min-device-width: 1179px)">
            <div className="services">
              <PageHeader
                image="https://i.imgur.com/dc1TYS9.png"
                text="Services"
              />
              <img
                className="services-img-desktop"
                src={ServicesEnglishDesktop}
              />
            </div>
          </MediaQuery>
        </div>
      );
    } else {
      return (
        <div>
          <MediaQuery query="(max-device-width: 764px)">
            <div className="services-page-mobile">
              <PageHeaderMobile
                image="https://i.imgur.com/dc1TYS9.png"
                text="Prestations de service"
              />
              <div className="services-mobile-img-container">
                <img
                  className="services-img-mobile"
                  src={ServicesFrenchMobile1}
                />
                <img
                  className="services-img-mobile"
                  src={ServicesFrenchMobile2}
                />
              </div>
              <div className="services-mobile-img-container">
                <img
                  className="services-img-mobile"
                  src={ServicesFrenchMobile3}
                />
                <img
                  className="services-img-mobile"
                  src={ServicesFrenchMobile4}
                />
              </div>
            </div>
          </MediaQuery>
          <MediaQuery query="(max-device-width: 1056px) and (min-device-width:765px)">
            <div className="services-page-mobile">
              <PageHeaderMobile
                image="https://i.imgur.com/dc1TYS9.png"
                text="Prestations de service"
              />
              <img
                className="services-img-desktop"
                src={ServicesFrenchDesktop}
              />
            </div>
          </MediaQuery>
          <MediaQuery query="(max-device-width: 1179px) and (min-device-width:1056px)">
            <div className="services-page-mobile">
              <PageHeaderMobile
                image="https://i.imgur.com/dc1TYS9.png"
                text="Prestations de service"
              />
              <img
                className="services-img-desktop"
                src={ServicesFrenchDesktop}
              />
            </div>
          </MediaQuery>
          <MediaQuery query="(min-device-width: 1179px)">
            <div className="services">
              <PageHeader
                image="https://i.imgur.com/dc1TYS9.png"
                text="Prestations de service"
              />
              <img
                className="services-img-desktop"
                src={ServicesFrenchDesktop}
              />
            </div>
          </MediaQuery>
        </div>
      );
    }
  }
}

export default ServicesPage;
