import React, { useState } from 'react';
import Spinner from 'react-spinner-material';

export default function EbookItem ({
  bookName,
  bookDescription, 
  bookId,
  price,
  bookCoverImageSrc,
  isEnglish,
  buyButtonOnClick
}) {

  const [ checkoutProcessing, setCheckoutProcessing ] = useState(false);

  const purchaseOnClick = (bookId) => {
    setCheckoutProcessing(true);
    buyButtonOnClick(bookId);
  }

  return (
    <div className="item-row">
      <div 
        className="preview-image-container" 
        style={{backgroundImage: `url(${bookCoverImageSrc})`}} 
        onClick={() => purchaseOnClick(bookId)}
      />
      <div className="item-content-container">
        <span className="item-title">{bookName}</span>
        <div className="item-pricing">
          {`$${price}`}
        </div>
        <button 
          className={`item-buy-button ${!isEnglish ? 'french' : ''}`} 
          onClick={() => purchaseOnClick(bookId)}
        >
          {
            checkoutProcessing ? 
              <Spinner size={15} spinnerColor={"#8d8d8d"} spinnerWidth={2} visible={true} />
            : 
              isEnglish ? 'Buy' : 'Acheter'
          }
        </button>
      </div>
    </div>
  )
}