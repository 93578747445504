import React from 'react';
import { isProperty } from '@babel/types';

class SponsorsQuestion extends React.Component{
 
  constructor(prop){
    super(prop);
  }

  render(){
    const { header, type, onChange } = this.props;

    return (
      <div className="sponsors-page-question">
            <h3>{header}</h3>
            <input 
              type={type === "number" ? "number" : "text"} 
              placeholder={this.props.placeholder} 
              onChange={(e) => onChange(e.target.value)}
            />
      </div>
    );
  }
}

export default SponsorsQuestion;
