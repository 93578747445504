import React, { Component } from "react";
import "../../scss/leftSideBar/blog-post.scss";
import ShowMoreText from 'react-show-more-text';
import MediaQuery from 'react-responsive';

class BlogPost extends Component {
  createMarkup() {
    return {__html: this.props.innerHTML};
  }

  render() {
    const { isEnglish } = this.props;

    return (
      <div className="blog_post" dangerouslySetInnerHTML={this.createMarkup()} />
    );
  }
}

export default BlogPost;
