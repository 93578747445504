import React from 'react';
import '../../scss/rightSideBar/right-side-board.scss';

class RightSideBoard extends React.Component{

  constructor(prop){
    super(prop);
  }

  render(){
    const isEnglish = this.props.language === 'en';
    const culturalBillboard = this.props.culturalBillboard;
    return (
      <div className="right-side-board">
        <h2 className="right-side-board-header">{isEnglish ? "Cultural Billboard" : "Babillard culturel"}</h2>
        <img className="right-side-board-image" src={culturalBillboard.image}></img>
        <p className="right-side-board-text">{isEnglish ? culturalBillboard.enTitle : culturalBillboard.frTitle}</p>
      </div>
    );
  }
}

export default RightSideBoard;
