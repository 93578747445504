import React from 'react';
import '../../scss/shopping_page/order-summary.scss';
import Modal from 'react-responsive-modal';
import { availableItems } from './items';

const OrderSummary = ({ 
  open,
  isEnglish,
  canadianComicBookQuantity=0,
  leCanadianQuantity=0,
  theCanadianQuantity=0,
  kassavQuantity=0,
  eclatempsQuantity=0,

  shippingInfo,
  orderItemComponents,
  confirmButtonOnClick,
  shippingInfoChangeButtonOnClick
}) => {
  const itemsInfo = availableItems[`${isEnglish ? 'en' : 'fr'}`];
  const canadianComicBookPricing = canadianComicBookQuantity*20;
  const leCanadianPricing = leCanadianQuantity*16;
  const theCanadianPricing = theCanadianQuantity*25;
  const kassavPricing = kassavQuantity*27.95;
  const eclatempsPricing = eclatempsQuantity*20.00;
  // TODO ask jack about shipping cost (it's free with new book?)
  const shippingCost = (canadianComicBookQuantity + leCanadianQuantity + theCanadianQuantity + kassavQuantity)*5;
  const totalPrice = eclatempsPricing + canadianComicBookPricing + leCanadianPricing + theCanadianPricing + kassavPricing + shippingCost;
  
  return (
    <Modal open={open} showCloseIcon={false} focusTrapped={false} onClose={() => {}} center>
      <h2 className="modal-title">{isEnglish ? 'Review your order' : 'Vérifiez votre commande'}</h2>
      <div id="order-summary-container">
        <div className="order-summary bordered-container">
          <div className="row title-text">{isEnglish ? 'Order Summary' : 'Détails de la commande'}</div>
          {
            canadianComicBookQuantity !== null &&
            <div className="row order-item-row">
              <div>{itemsInfo.canadianComicBook.title} x {canadianComicBookQuantity}</div>
              <div>${canadianComicBookPricing}</div>
            </div>
          }
          {
            leCanadianQuantity !== null &&
            <div className="row order-item-row">
              <div>{itemsInfo.leCanadian.title}x{leCanadianQuantity}</div>
              <div>${leCanadianPricing}</div>
            </div>
          }
          {
            theCanadianQuantity !== null &&
            <div className="row order-item-row">
              <div>{itemsInfo.theCanadian.title}x{theCanadianQuantity}</div>
              <div>${theCanadianPricing}</div>
            </div>
          }
          {
            kassavQuantity !== null &&
            <div className="row order-item-row">
              <div>{itemsInfo.kassav.title}x{kassavQuantity}</div>
              <div>${kassavPricing}</div>
            </div>
          }
           {
            eclatempsQuantity !== null &&
            <div className="row order-item-row">
              <div>{itemsInfo.eclamtemps.title}x{eclatempsQuantity}</div>
              <div>${eclatempsPricing}</div>
            </div>
          }
          <div className="row order-item-row">
            <div>{isEnglish ? 'Shipping' : 'Livraison'}:</div>
            <div>${shippingCost}</div>
          </div>
          <hr className="order-item-row"/>
          <div className="row order-item-row">
            <div>{isEnglish ? 'Total before tax' : 'Total avant taxes'}</div>
            <div>${totalPrice}</div>
          </div>
          <div className="row order-item-row">
            <div>{isEnglish ? 'Tax' : 'Taxes'}:</div>
            <div>{isEnglish ? 'Included' : 'incluses'}</div>
          </div>
          <hr className="order-item-row"/>
          <div className="row order-item-row order-total">
            <div>{isEnglish ? 'Order total' : 'Total'}</div>
            <div>${totalPrice}</div>
          </div>

          <div className="row payment-message">
            {
              isEnglish ?
              <span>
                Please E-transfer the Order Total amount to irene@boxia.ca to complete order <br/>
                <span style={{color: "black"}}>Or</span><br/>
                Follow the instructions in the confirmation email to pay with debit, credit card or PayPal.
              </span>
              :
              <span>
                Veuillez envoyer un virement Interac à l’adresse courriel irene@boxia.ca pour compléter la commande <br/>
                <span style={{color: "black"}}>Or</span><br/>
                Veuillez suivre les directives dans le courriel de confirmation pour payer avec votre carte de débit, crédit ou PayPal.
              </span>
            }
            
          </div>

          <div className="order-item-row">
            <button className="order-confirmation-button" onClick={confirmButtonOnClick}>
              {isEnglish ? 'Place order' : 'Commandez'}
            </button>
          </div>
        </div>

        <div className="desktop-column-wrapper">
          {/* Shipping Information */}
          <div className="shipping-information bordered-container">
            <div className="title-text">
              {
                isEnglish ?
                'Shipping Information'
                :
                'Informations sur la livraison'
              }
              <span onClick={shippingInfoChangeButtonOnClick} className="change-button">{isEnglish ? 'Change' : 'Changement'}</span>
            </div>
            <div className="order-item-row">{shippingInfo.name}</div>
            <div className="order-item-row">{shippingInfo.address}</div>
            <div className="order-item-row">{`${shippingInfo.city}, ${shippingInfo.province}. ${shippingInfo.postalCode}`}</div>
            <div className="order-item-row phone-row">{isEnglish ? 'Phone' : 'Numéro de téléphone'}: {shippingInfo.phoneNumber}</div>
          </div>

          {/* Items */}
          <div className="order-items-container bordered-container">
            {orderItemComponents}
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default OrderSummary;