import React from 'react';
import '../../scss/shopping_page/shipping-form.scss';

const ShippingForm = ({fieldOnChange, continueOnClick, isEnglish, validationMessage}) => {
  const inputFieldOnChange = e => {
    fieldOnChange(e.target.name, e.target.value)
  };

  return(
    <div id="shipping-form">
      <h3 className="header">{isEnglish ? 'Shipping Information' : 'Informations sur la livraison'}</h3>
      <div className="information-form-container">
        <div className="input-info-name">
          <span className="input-title">{isEnglish ? 'Full Name' : 'Nom au complet'}</span>
          <input name="name" onChange={inputFieldOnChange} placeholder="First name, Last name"/>
        </div>
        <div className="input-info-name">
          <span className="input-title">{isEnglish ? 'Email Address' : 'Adresse courriel'}</span>
          <input name="email" onChange={inputFieldOnChange} placeholder="Guest@boxia.ca"/>
        </div>
        <hr/>
        <div className="input-info-container">
          <div className="box1">
            <span className="input-title">{isEnglish ? 'Address' : 'Adresse'}</span>
            <input name="address" onChange={inputFieldOnChange} placeholder={isEnglish ? '(Apt/suite# - ) Street number, Street name' : '(Apt / suite # -) Numéro de rue, nom de rue'}/>
          </div>

          <div className="box2">
            <span className="input-title">{isEnglish ? 'City' : 'Ville'}</span>
            <input name="city" onChange={inputFieldOnChange} placeholder="Ottawa"/>
          </div>
        </div>
        <div className="input-info-container">
          <div className="box1">

            <span className="input-title">Province</span>
            <input name="province" onChange={inputFieldOnChange} placeholder="Ontario"/>
          </div>

          <div className="box2">
            <span className="input-title">{isEnglish ? 'Postal Code' : 'Code postal'}</span>
            <input name="postalCode" onChange={inputFieldOnChange} placeholder="H0H0H0"/>
          </div>
        </div>
        <div className="input-info-name">
          <span className="input-title">{isEnglish ? 'Phone Number' : 'Numéro de téléphone'}</span>
          <input name="phoneNumber" onChange={inputFieldOnChange} placeholder="9876543210"/>
        </div>
        <button className="continue-button" onClick={continueOnClick}>
          {isEnglish ? 'Continue' : 'Continuez'}
        </button>
        {
          validationMessage !== null &&
          <div className="validation-message">
            {validationMessage}
          </div>
        }
      </div>

    </div>
  )
}

export default ShippingForm;