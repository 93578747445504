import React from 'react';

class ServicesSquare extends React.Component{
 
  constructor(prop){
    super(prop);
  }

  render(){
    return (
    <div className="services-page-square"> 
        <img className="services-page-square-image" src={this.props.img}></img>  
        <div className="services-page-square-overlay">
            <p className="services-page-square-overlay-text">{this.props.text}</p>
        </div>
    </div>
    );
  }
}

export default ServicesSquare;
