import React from 'react';
import '../scss/announcement.scss';
import YouTube from 'react-youtube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

class Announcement extends React.Component{

  // eslint-disable-next-line no-useless-constructor
  constructor(prop){
    super(prop);
    this.seriesIds = [
      "NmakzewFFIY",
      "4EwTEhgAxIU",
      "qIv2omkR160",
      "BbvOHF1M49k",
      "Xxii2rYUu70",
      "Agv1k0Dk_6U",
      "5VvI-RXqHVs",
    ];

    this.state={
      currentYoutubeIndex: this.seriesIds.length - 1
    }

    this.previousOnClick = this.previousOnClick.bind(this);
    this.nextOnClick = this.nextOnClick.bind(this);
  }
  
  previousOnClick(){
    const { currentYoutubeIndex } = this.state;
    if(currentYoutubeIndex !== 0){
      this.setState({
        currentYoutubeIndex: currentYoutubeIndex - 1
      });
    }
  }

  nextOnClick(){
    const { currentYoutubeIndex } = this.state;
    if(currentYoutubeIndex < this.seriesIds.length){
      this.setState({
        currentYoutubeIndex: currentYoutubeIndex + 1
      });
    }
  }

  render(){
    const { language } = this.props;
    const { currentYoutubeIndex } = this.state;

    return (
      <div className="announcement-container">
        <YouTube 
          videoId={this.seriesIds[currentYoutubeIndex]}
          className="youtube-player"
        />
        <div className="series-buttons">
          {
            currentYoutubeIndex === 0 ?
            <span></span>
            :
            <div className="button" onClick={this.previousOnClick}>
              <FontAwesomeIcon icon={faArrowLeft} />
              {' '}Previous
            </div>
          }
          {
            currentYoutubeIndex === this.seriesIds.length - 1 ?
            <span></span>
            :
            <div className="button" onClick={this.nextOnClick}>
              <FontAwesomeIcon icon={faArrowRight} />
              {' '}Next
            </div>
          }
        </div>

        <div className='announcement-title'>
          {
            language === "en" ?
            "The Politician & His/Her Idol"
            :
            "Le Politicien et son Idole"
          }
        </div>
        <div className='announcement-description'>
          {
            language === "en" ?
            "The Talk Show consists of a 30-minute interview in English or French. Politicians will speak about their idols or their source of motivation/inspiration, someone they admire or their role model whose values and talents have, among other things, shaped who they have become today. This can cover a variety of spheres of influence: arts and culture (musicians, poets, comedians), sports, historical figures, politics, activism, etc."
            :
            "L'émission consiste en une entrevue de 30 minutes en anglais ou français. Les politiciens parlent de leurs idoles de jeunesse ou de leur source de motivation/inspiration, de quelqu'un qu'ils admirent ou de leur modèle dont les valeurs et les talents ont entre autres façonné l’adulte qu’ils sont devenus. Cela peut couvrir une variété de domaines de vie: Art et culture (musiciens, poètes, comédiens), sport, personnages historiques, politique, activisme, etc."
          }
        </div>
      </div>
    );
  }
}

export default Announcement;