import React from 'react';
import '../../scss/rightSideBar/right-side-donation.scss';

class RightSideDonation extends React.Component{

  constructor(prop){
    super(prop);
  }

  render(){
    if(this.props.language === "en"){
      return (
        <div className="right-side-donation">
          <a className="dbox-donation-button" href="https://donorbox.org/boxia-partage-donation?hide_donation_meter=true" target="_blank">Make a donation</a>
        </div>
      );
    }else{
      return (
        <div className="right-side-donation">
            <a className="dbox-donation-button" href="https://donorbox.org/boxia-partage-donation?hide_donation_meter=true" target="_blank">Faire un don</a>
        </div>
      );
    }
}
}

export default RightSideDonation;
