import React, { useState, useEffect } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Alert } from 'react-bootstrap'
import { getApiUrl } from '../../Util';
import Cookies from "js-cookie";
import '../../scss/login-panel.scss';
import axios from 'axios';

function LoginPanel({isEnglish}) {
  const [loginMessage, setLoginMessage] = useState(undefined);

  const onSubmit = (element) => {
    element.preventDefault();
    const email = element.target.email.value;
    const password = element.target.password.value;

    axios.post(
      `${getApiUrl()}/authenticate`,
      { 
        email: email,
        password: password
      }
    )
      .then(res => {
        Cookies.set("auth-token", res.data.auth_token);
        window.location.reload(false);
      })
      .catch(() => setLoginMessage(
        isEnglish ? 
          "Email or password is incorrect. Please try again or contact client.team@boxia.ca for support"
        : 
          "E-mail ou mot de passe incorrect. Veuillez réessayer ou contacter client.team@boxia.ca pour obtenir de l'aide"
      ))
  }

  return (
    <div className="login-panel-container">
      <Card className="form-container">
        <form onSubmit={onSubmit}>
          <Card.Body>
            <div className="form-group">
              <label>{isEnglish ? "Email" : "Courriel"}</label>
              <input type="email" className="form-control" name="email" placeholder={isEnglish ? "Email" : "Courriel"} />
            </div>

            <div className="form-group">
              <label>{isEnglish ? "Password" : "Mot de passe"}</label>
              <input type="password" className="form-control" name="password"/>
            </div>

            <button type="submit" className="btn btn-primary btn-block">
              {isEnglish ? "Submit" : "Soumettre"}
            </button>
            
            {
              loginMessage &&
              <Alert variant="warning">
                {loginMessage}
              </Alert>
            }
          </Card.Body>
        </form>
      </Card>
    </div>
  )
}


export default LoginPanel
