import React from 'react';
import LeftSideBar from './leftSideBar/LeftSideBar';
import RightSideBar from './rightSideBar/RightSideBar';
import ComicOfTheDay from './ComicOfTheDay';
import MediaQuery from 'react-responsive';
import Announcement from './Announcement';

class HomePage extends React.Component {
  constructor(prop) {
    super(prop);
  }

  state = {
    language: this.props.language || 'en',
    comicToDisplay: this.props.data.allComicOfTheDay[0],
    indexOfDisplayComic: 0,
    comicID: this.props.data.allComicOfTheDay[0].id,
  }

  updateComic = (valueToChangeComic) => {
    let newComicID = parseInt(this.state.comicID, 10) + valueToChangeComic;
    let newComicIndex = parseInt(this.state.indexOfDisplayComic, 10) + (valueToChangeComic * -1);
    const { allComicOfTheDay } = this.props.data;
    this.setState({
      comicToDisplay: allComicOfTheDay[newComicIndex],
      indexOfDisplayComic: newComicIndex,
      comicID: newComicID,
    });
  }

  render() {
    return (
      <div id='content'>
        <MediaQuery query='(min-device-width: 1040px)'>
          <LeftSideBar language={this.props.language} whatsNew={this.props.data.whatsNew[0]} />
          <div className="center-column">
            <Announcement language={this.props.language}/>
            <ComicOfTheDay language={this.state.language} numberOfComicsInArray={this.props.data.allComicOfTheDay.length} indexInComicArray={this.state.indexOfDisplayComic} comicIndex={this.state.comicID} comicOfTheDay={this.state.comicToDisplay} updateComic={this.updateComic} />
          </div>
          <RightSideBar language={this.props.language} allEvents={this.props.data.allEvents} wordsOfLove={this.props.data.wordOfLove} wordsOfWisdom={this.props.data.wordOfWisdom} culturalBillboard={this.props.data.culturalBillboard}/>
        </MediaQuery>
        <MediaQuery query='(max-device-width: 1040px)'>
          <div className="center-column">
            <Announcement language={this.props.language}/>
            <ComicOfTheDay language={this.state.language} numberOfComicsInArray={this.props.data.allComicOfTheDay.length} indexInComicArray={this.state.indexOfDisplayComic} comicIndex={this.state.comicID} comicOfTheDay={this.state.comicToDisplay} updateComic={this.updateComic} />
          </div>
          <LeftSideBar language={this.props.language} whatsNew={this.props.data.whatsNew[0]} />
          <RightSideBar language={this.props.language} allEvents={this.props.data.allEvents} wordsOfLove={this.props.data.wordOfLove} wordsOfWisdom={this.props.data.wordOfWisdom} culturalBillboard={this.props.data.culturalBillboard}/>
        </MediaQuery>
      </div>
    );
  }
}

export default HomePage;

