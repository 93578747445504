import React from 'react';
import '../../scss/shopping_page/item-row.scss';
import { availableItems } from './items';

const PopulatedItemRow = ({
  itemName,
  isEnglish,
  orderQuantity,
  imageOnClick,
  buyButtonOnClick,
  quantityOnChange,
}) => {
  const itemContent = availableItems[`${isEnglish ? 'en' : 'fr'}`][itemName];
  return (
    <ItemRow 
      title={itemContent["title"]}
      pricingComponent={itemContent["priceComponent"]}
      imageSrc={itemContent["previewImage"]} 
      imageOnClick={() => imageOnClick(itemName)}
      orderQuantity={orderQuantity}
      buyButtonOnclick={buyButtonOnClick}
      quantityOnChange={quantityOnChange}
      isEnglish={isEnglish}
    />
)}

const ItemRow = ({ 
  imageSrc, 
  title, 
  pricingComponent, 
  imageOnClick, 
  buyButtonOnclick,
  orderQuantity,
  quantityOnChange,
  isEnglish
}) => (
  <div className="item-row">
    <div className="preview-image-container" onClick={imageOnClick} style={{backgroundImage: `url(${imageSrc})`}}></div>
    <div className="item-content-container">
      <span className="item-title">{title}</span>
      <div className="item-pricing">
        {pricingComponent}
      </div>
      {
        orderQuantity === null ?
          <button className={`item-buy-button ${!isEnglish ? 'french' : ''}`} onClick={buyButtonOnclick}>{isEnglish ? 'Buy' : 'Acheter'}</button>
        :
          <div className="quantity-selection-container">
            {isEnglish ? 'Quantity' : 'Quantité'}:
            <select className="quantity-selection" value={orderQuantity} onChange={e => quantityOnChange(e.target.value) }>
              {
                [1,2,3,4,5,6,7,8,9].map((availableQuantity) => (
                <option key={`${title}-quantity-${availableQuantity}`} value={availableQuantity}>{availableQuantity}</option>
                ))
              }
            </select>
          </div>
      }
      <span className="item-shipping-message">{isEnglish ? 'Free shipping included' : 'Livraison incluse'}</span>
    </div>
  </div>
)

export default PopulatedItemRow;