import React, { Component } from "react";
import "../../scss/about_bio_pages/about-page.scss";
import "../../scss/about_bio_pages/bio-page.scss";
import Bio from "./Bio";
import BioPage from "./BioPage";
import TextForFounders from "./textForFounder.json";
import Slider from "react-slick";
import MediaQuery from 'react-responsive';

class aboutPage extends Component {
  state = {
    biosActive: true,
    aboutUs: "About us",
    paraOne: " ",
    mission: " ",
    missionStatement: " ",
    missionFourWords: [" ", " ", " ", " "],
    vision: "Vision",
    visionQuote: " ",
    values: " ",
    valuesFourWords: [" ", " ", " ", " "],
    founders: " ",
    BodyTitle: " ",
    IreneTitle: " ",
    BodyParaOne: " ",
    BodyParaTwo: " ",
    BodyParaThree: " ",
    BodyParaFour: " ",
    IreneParaOne: " ",
    IreneParaTwo: " ",
    IreneParaThree: " ",
    IreneParaFour: " ",
    IreneParaFive: " ",
    IreneParaSix: " ",
    IreneParaSeven: " ",
  };
  componentDidMount() {
    this.setState({
      biosActive: false
    });
    let isEnglish = true;
    let language = 'en';
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('language')) {
      if (searchParams.get('language') == 'en') {
        isEnglish = true;
      } else {
        isEnglish = false;
      }
    } else {
      window.location.search = 'language=en';
    }
    if (isEnglish) {
      language = 'en';
    } else {
      language = 'fr';
    }
    if (isEnglish) {
      this.setState({
        aboutUs: "About us",
        paraOne:
          "We are a private social enterprise that promotes and supports local businesses, communities, initiatives, events, activities, public engagements, organizations and structures.",
        mission: "Mission",
        missionStatement:
          "We aim to create and provide an educational platform that is designed to encourage intellectual exchange, product and service offerings among potential clients and partners, all the while gaining interpersonal and intercultural competencies. The overarching purpose is to create the IPVA virtual space for the initiatives of our local, national and international communities.",
        missionFourWords: [
          "Information",
          "Promotion",
          "Visibility",
          "Awareness"
        ],
        vision: "Vision",
        visionQuote: '" Let us make our big world a small one "',
        values: "Values",
        valuesFourWords: [
          "Love",
          "Diversity ",
          "Respect",
          "Service Excellence"
        ],
        founders: "Founders",
        BodyTitle: "President & Senior Advisor",
        IreneTitle: "Founder & CEO",
        biographies: "Biographies",
        BodyParaOne: TextForFounders.BodyParaOneE,
        BodyParaTwo: TextForFounders.BodyParaTwoE,
        BodyParaThree: TextForFounders.BodyParaThreeE,
        BodyParaFour: TextForFounders.BodyParaFourE,
        IreneParaOne: TextForFounders.IreneParaOneE,
        IreneParaTwo: TextForFounders.IreneParaTwoE,
        IreneParaThree: TextForFounders.IreneParaThreeE,
        IreneParaFour: TextForFounders.IreneParaFourE,
        IreneParaFive: TextForFounders.IreneParaFiveE,
        IreneParaSix: TextForFounders.IreneParaSixE,
        IreneParaSeven: TextForFounders.IreneParaSevenE
      });
    } else {
      this.setState({
        biosActive: false,
        aboutUs: "À propos de nous",
        paraOne:
          "Nous sommes une entreprise sociale privée qui promeut et soutient les entreprises locales, les communautés, les initiatives, les événements, les activités, les engagements publics et toutes autres structures organisationnelles (conseils scolaires, ONG, coopératives, caisses populaires, etc.)",
        mission: "Mission",
        missionStatement:
          "Créer et développer une plate-forme virtuelle pour encourager les échanges des savoirs, des produits et services entre des clients et partenaires potentiels, tout en acquérant des compétences interpersonnelles et interculturelles. Cet espace virtuel est un outil d’IPVS pour les initiatives des communautés locales, nationales et internationales.",
        missionFourWords: [
          "Information",
          "Promotion",
          "Visibilité",
          "Sensibilisation"
        ],
        vision: "Vision",
        visionQuote: '" Rendons notre monde plus petit. "',
        values: "Valeurs",
        valuesFourWords: [
          "Amour",
          "Diversité ",
          "Respect",
          "Excellence du service"
        ],
        founders: "Fondateurs",
        BodyTitle: "Président et conseiller principal",
        IreneTitle: "Fondatrice & Directrice Générale",
        biographies: "Biographie",
        BodyParaOne: TextForFounders.BodyParaOneF,
        BodyParaTwo: TextForFounders.BodyParaTwoF,
        BodyParaThree: TextForFounders.BodyParaThreeF,
        BodyParaFour: TextForFounders.BodyParaFourF,
        IreneParaOne: TextForFounders.IreneParaOneF,
        IreneParaTwo: TextForFounders.IreneParaTwoF,
        IreneParaThree: TextForFounders.IreneParaThreeF,
        IreneParaFour: TextForFounders.IreneParaFourF,
        IreneParaFive: TextForFounders.IreneParaFiveF,
        IreneParaSix: TextForFounders.IreneParaSixF,
        IreneParaSeven: TextForFounders.IreneParaSevenF
      });
    }
  }
  switchToBios = () => {
    this.setState({
      biosActive: true
    });
  };

  render() {
    const { state } = this;
    const isEngligh = this.props.language == "en";
    const carouselSettings = {
      className: "see-more-photo-carousel carousel_margin_fix",
      dots: true,
      infinite: true,
      slidesToShow: 2,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3500,
    };

    if (this.state.biosActive) {
      return (
        <div id="bio_page" className="bio_page">
          <div className="bio_page_content">
            <div className="bio_page_title">
              <h1>{state.biographies}</h1>
            </div>
            <div className="spacer_div_bio_page" />
            <div className="spacer_div_bio_page" />
            <BioPage
              image={require("../../img/Irene-Photo.jpg")}
              name="Irene Xia Zhou"
              title={state.IreneTitle}
              paragraphs={[
                state.IreneParaOne,
                state.IreneParaTwo,
                state.IreneParaThree,
                state.IreneParaFour,
                state.IreneParaFive,
                state.IreneParaSix,
                state.IreneParaSeven,
              ]}
            />
            <MediaQuery query="(min-device-width: 564px)">
              <div className="spacer_div_bio_page" />
            </MediaQuery>

            <div className="carousel-container">
              <MediaQuery query="(min-device-width: 564px)">
                <Slider {...carouselSettings}>
                  <div><img src="https://i.ibb.co/HK8NSCf/See-More-Photos-2.jpg"></img></div>
                  <div><img src="/gallary/Jocelyne 2.jpeg"></img></div>
                  <div><img src="/gallary/Jocelyne KASSAV.jpeg"></img></div>
                  <div><img src="/gallary/uOGlobal.jpeg"></img></div>
                </Slider>
              </MediaQuery>
              <MediaQuery query="(max-device-width: 564px)">
                <div><img src="https://i.ibb.co/cFFBMKx/See-More-Photos-2.jpg"></img></div>
              </MediaQuery>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="page">
          <div className="about_page_content">
            <div className="title_about_us">
              <div className="dark_overlap_div">
                <h1>{state.aboutUs}</h1>
              </div>
            </div>
            <div className="background">
              <div className="firstPara">
                <h3>{state.paraOne}</h3>
              </div>
              <div className="mission">
                <h1>{state.mission}</h1>
                <MediaQuery query="(min-device-width: 564px)">
                  <h4>{state.missionStatement}</h4>
                  <div className="four_words">
                    <div className="left_words">
                      <h3 className="top_word">{state.missionFourWords[0]}</h3>
                      <h3 className="bottom_word">
                        {state.missionFourWords[1]}
                      </h3>
                    </div>
                    <div className="left_words">
                      <h3 className="top_word">{state.missionFourWords[2]}</h3>
                      <h3 className="bottom_word">
                        {state.missionFourWords[3]}
                      </h3>
                    </div>
                  </div>
                </MediaQuery>
                <MediaQuery query="(max-device-width: 1040px)">
                  <div className="mission_content">
                    <div className="four_words">
                      <h3 className="">{state.missionFourWords[0]}</h3>
                      <h3 className="">
                        {state.missionFourWords[1]}
                      </h3>
                      <h3 className="">{state.missionFourWords[2]}</h3>
                      <h3 className="">
                        {state.missionFourWords[3]}
                      </h3>
                    </div>
                    <h4>{state.missionStatement}</h4>
                  </div>
                </MediaQuery>
              </div>
              <div className="vision">
                <h2>{state.vision}</h2>
                <h1>{state.visionQuote}</h1>
              </div>
              <div className="values">
                <h1>{state.values}</h1>
                <MediaQuery query="(min-device-width: 564px)">
                  <div className="values_words">
                    <h2 className="values_first_word">
                      {state.valuesFourWords[0]}
                    </h2>
                    <h2 className="values_middle_words">
                      {state.valuesFourWords[1]}
                    </h2>
                    <h2 className="values_middle_words">
                      {state.valuesFourWords[2]}
                    </h2>
                    <h2 className="values_last_word">
                      {state.valuesFourWords[3]}
                    </h2>
                  </div>
                </MediaQuery>
                <MediaQuery query="(max-device-width: 564px)">
                  <div className="values_words">
                    <div className='values_first_word'>
                      <h2 className="top_word">
                        {state.valuesFourWords[0]}
                      </h2>
                      <h2 className="">
                        {state.valuesFourWords[1]}
                      </h2>
                    </div>
                    <div className='values_last_word'>
                      <h2 className="top_word">
                        {state.valuesFourWords[2]}
                      </h2>
                      <h2 className="">
                        {state.valuesFourWords[3]}
                      </h2>
                    </div>
                  </div>
                </MediaQuery>
              </div>
              <div className="founders">
                <h1>{state.founders}</h1>
                <div className="bios">
                  <Bio
                    goToBios={this.switchToBios}
                    image={require("../../img/Irene-Photo.jpg")}
                    name="Irene Xia Zhou"
                    title={state.IreneTitle}
                    para={state.IreneParaOne}
                    isEnglish={isEngligh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default aboutPage;
