import React from 'react';
import './scss/global.scss';
import NavBar from './components/NavBar';
import Content from './components/Content';
import Footer from './components/Footer';
import Spinner from 'react-spinner-material';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ReactGA from 'react-ga';
import NavBarMobile from './components/NavBarMobile';
import MediaQuery from 'react-responsive';
import { getApiUrl } from '../src/Util';

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      language: "en",
      comicIndex: 0,
      data: {}
    }

    this.switchLanguage = this.switchLanguage.bind(this)
    this.previousComic = this.previousComic.bind(this)
    this.nextComic = this.nextComic.bind(this)
    this.requestData = this.requestData.bind(this)
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('UA-142572948-1');
      ReactGA.pageview('/homepage');
    }
    this.requestData();
  }

  requestData() {
    const query = `
    {
      allEvents {
        id
        enTitle
        enDescription
        frTitle
        frDescription
        img
      }
      wordOfLove {
        id
        author
        by
        enContent
        frContent
      }
      wordOfWisdom {
        id
        author
        by
        enContent
        frContent
      }
      comicOfTheDay {
        id
        enTitle
        frTitle
        enDescription
        frDescription
        enImg
        frImg
      }
      allComicOfTheDay{
        id
        enTitle
        frTitle
        enDescription
        frDescription
        enImg
        frImg
      }
    
      # Pages
      servicesPage {
        enContent frContent
      }
      toolsPage {
        enContent frContent
      }
      aboutPage {
        enContent frContent
      }
      sponsorsPage {
        enContent frContent
      }
      contactUsPage{
        enContent frContent
      }
      culturalBillboard{
        image enTitle frTitle
      }
      whatsNew{
        enContent, frContent
      }
    }`;

    const opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query }),
      mode: 'cors'
    };

    fetch(`${getApiUrl()}/graphql`, opts)
      .then(res => res.json())
      .then(res => {
        this.setState({
          data: res,
          isLoading: false
        })
      })
      .then(res => {
        this.setState({
          comicIndex: this.state.data.data.allComicOfTheDay.length - 1
        })
      })
      .catch(console.error)
  }

  switchLanguage() {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('language')) {
      if (searchParams.get('language') == 'fr') {
        let searchEn = window.location.search.replace('fr', 'en');
        window.location.href = window.location.pathname + searchEn;
      } else {
        let serachFr = window.location.search.replace('en', 'fr');
        window.location.href = window.location.pathname + serachFr;
      }
    } else {
      window.location.search = 'language=en';
    }
  }

  previousComic() {
    if (this.state.comicIndex > 0) {
      this.setState({
        isLoading: true
      })

      setTimeout(() => {
        this.setState(prevState => {
          return {
            isLoading: false,
            comicIndex: prevState.comicIndex - 1
          }
        })
      }, 250)
    }

  }

  nextComic() {
    if (this.state.comicIndex < this.state.data.data.allComicOfTheDay.length - 1) {
      this.setState({
        isLoading: true
      })

      setTimeout(() => {
        this.setState(prevState => {
          return {
            isLoading: false,
            comicIndex: prevState.comicIndex + 1
          }
        })
      }, 250)
    }
  }

  render() {
    return (
      <div className="App">
        <Router>
        <MediaQuery query="(min-device-width: 1179px)">
            <NavBar language={this.state.language} onClick={this.switchLanguage}/>
            <Load language={this.state.language} comicIndex={this.state.comicIndex} data={this.state.data.data} isLoading={this.state.isLoading} nextClick={this.nextComic} previousClick={this.previousComic} />
            <Footer language={this.state.language} />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1178px)">
          <NavBarMobile language={this.state.language} onClick={this.switchLanguage}/>
          <Load language={this.state.language} comicIndex={this.state.comicIndex} data={this.state.data.data} isLoading={this.state.isLoading} nextClick={this.nextComic} previousClick={this.previousComic} />
          <Footer language={this.state.language} />
        </MediaQuery>
        </Router>
      </div>
    );
  }
}

export default App;

function Load(props) {
  if (props.isLoading === true) {
    return (
      <div className="loading-container">
        <div className="app-spinner">
          <Spinner size={100} spinnerColor={"#FFFFFF"} spinnerWidth={5} visible={true} />
        </div>
      </div>
    )
  } else {
    return (
      <div className="app">
        <Content language={props.language} comicIndex={props.comicIndex} data={props.data} nextClick={props.nextClick} previousClick={props.previousClick} />
      </div>
    )
  }
}
