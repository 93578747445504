import React from 'react';
import '../../scss/leftSideBar/left-side-bar.scss';
import BlogPost from "./BlogPost";
class LeftSideBar extends React.Component {
  render() {
    const isEnglish = this.props.language === 'en';
    return (
      <div className="left_side_bar">
        <h2>{isEnglish ? "What's New" : "Quoi de neuf"}</h2>
        {
          this.props.language === "en" ? 
            <BlogPost innerHTML={this.props.whatsNew.enContent}/>
            :
            <BlogPost innerHTML={this.props.whatsNew.frContent}/>
        }
      </div>
    );
  }
}

export default LeftSideBar;
