import React, { useState } from 'react';
import '../../scss/shopping_page/ebook_shopping_section.scss';
import theCanadianCoverEn from '../../img/shopping_page/the_canadian/en.jpg';
import theCanadianCoverFr from '../../img/shopping_page/the_canadian/fr.jpg';
import theCanadianDreamCoverEn from '../../img/shopping_page/the_canadian_dream/en.jpg';
import theCanadianDreamCoverFr from '../../img/shopping_page/the_canadian_dream/fr.jpg';
import raveCanadianCover from '../../img/shopping_page/rave_canadian/cover.jpg';
import '../../scss/shopping_page/item-row.scss';
import { loadStripe } from "@stripe/stripe-js";
import { getApiUrl, getCurrentEnvironment } from '../../Util';
import axios from 'axios';
import EbookItem from './ebookItem';

const stripePromise = loadStripe(getCurrentEnvironment() === 'production' ? "pk_live_c44zFXE3h1QKE4c7MrU3fqZn00QH6rzYzz" : "pk_test_ux45wJmePYiqdqDaWBBj0RLY00daDeI1mJ");

function EbookShoppingSection ({isEnglish=true}){

  const buyButtonOnClick = async (bookId) => {
    const stripe = await stripePromise;
    axios.post(
      `${getApiUrl()}/checkout`,
      {
        ebook_id: bookId
      }
    )
    .then((response) => {
      stripe.redirectToCheckout({
        sessionId: response.data.id,
      });
    })
    .catch(() => {
      window.alert("Unable to process your request right now, please try again later");
    })
  };

  return(
    <div id="ebook-shopping-section-container">
      <div className="separator">E Book</div>
      <div className="ebook-containers">
        <EbookItem
          isEnglish={isEnglish}
          bookName="Comic Book 'The Canadian'"
          bookDescription="THE CANADIAN chronicles the story of a Canadian father of African descent teaching his teenage daughter Black History, while highlighting some First Nations values. This intriguing conversation happens while they explore 6 Canadian cities (Ottawa, Montréal, Halifax, Vancouver, Calgary, Toronto) for 6 days."
          bookId="1"
          price="15"
          bookCoverImageSrc={theCanadianCoverEn}
          buyButtonOnClick={buyButtonOnClick}
        />

        <EbookItem
          isEnglish={isEnglish}
          bookName="Bande dessinée ‘Le Canadien'"
          bookDescription="La bande dessinée Le CANADIEN relate une conversation entre un père et sa fille adolescente, issus des communautés afro descendantes canadiennes, sur l'histoire des Noirs au Canada, tout en soulignant quelques valeurs des premières nations. Cette fameuse conversation dure pendant 6 jours dans 6 villes du Canada (Ottawa, Montréal, Halifax, Vancouver, Calgary, Toronto)."
          bookId="2"
          price="15"
          bookCoverImageSrc={theCanadianCoverFr}
          buyButtonOnClick={buyButtonOnClick}
        />

        <EbookItem
          isEnglish={isEnglish}
          bookName="Le Rêve Canadien"
          bookDescription="Le Rêve Canadien est une bande dessinée qui se veut un outil éducatif et culturel d’information, de sensibilisation et de promotion de l’intégration des nouveaux arrivants francophones au sein de la collectivité franco-ontarienne. Le parcours de Patcho, le protagoniste de la BD, ne saurait s’avérer victorieux en l’absence du soutien des structures d’accueil et d’établissement franco-ontariennes. En filigrane, l’épopée de Patcho témoigne également de l’utilité de cette double plateforme franco-canadienne pour les personnages établis au pays depuis longtemps. En somme, tout un chacun peut y trouver sa place et y réaliser son rêve."
          bookId="5"
          price="15"
          bookCoverImageSrc={raveCanadianCover}
          buyButtonOnClick={buyButtonOnClick}
        />
      </div>

      <div className="ebook-containers">

        <EbookItem
          isEnglish={isEnglish}
          bookName="The Canadian Dream: Canadian Values"
          bookDescription="It highlights different values and characteristics common to the Canadian identity, thereby creating identity references and showcasing Canadians’ reputation to people from other parts of the world. In addition, this book features topics such as the Black History Month, Canada in the world, etc."
          bookId="3"
          price="13.5"
          bookCoverImageSrc={theCanadianDreamCoverEn}
          buyButtonOnClick={buyButtonOnClick}
        />

        <EbookItem
          isEnglish={isEnglish}
          bookName="Le Rêve canadien : Valeurs canadiennes"
          bookDescription="Elle met en évidence les valeurs et caractéristiques identitaires communes à l’ensemble de la population canadienne d’Est à l’Ouest du pays, créant ainsi des références identitaires et des réputations des Canadiennes et Canadiens auprès des autres peuples et nations du monde. En outre, elle fait un clin d’œil sur les sujets tels que le Mois de l'Histoire des Noirs, le Canada dans le monde, etc."
          bookId="4"
          price="13.5"
          bookCoverImageSrc={theCanadianDreamCoverFr}
          buyButtonOnClick={buyButtonOnClick}
        />
      </div>
    </div>
  )
}


export default EbookShoppingSection;