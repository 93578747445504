import React from 'react';
import '../../scss/rightSideBar/word-of-container.scss';

class WordOfContainer extends React.Component{

  constructor(prop){
    super(prop);
  }

  render(){
    const { content, author, by, header } = this.props;
    return (
      <div className="word-of-container">
        <h2 className="word-of-container-header">{header}</h2>
        <span className='person'>From {by}</span><br/>
        <span className='text'> {content} </span><br/>
        ({author})
      </div>
    );
  }
}

export default WordOfContainer;
