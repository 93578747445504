// For all utility functions
const envUrls = {
  production: "boxia.ca",
  production2: "www.boxia.ca",
  staging: "boxia.dokku.explorator.ca",
  local: "localhost:3000"
}

export const getApiUrl = () => {
  const currentHostName = window.location.hostname;

  switch(currentHostName){
    case envUrls.production:
      return "https://boxia-core.boxia.ca";
    
    case envUrls.production2:
      return "https://boxia-core.boxia.ca";

    case envUrls.staging:
      return "https://boxia-core.dokku.explorator.ca";

    default:
      return "http://localhost:4000";
  }
};

export const getCurrentEnvironment = () => {
  const currentHostName = window.location.hostname;

  switch(currentHostName){
    case envUrls.production:
      return "production";
    
    case envUrls.production2:
      return "production";

    case envUrls.staging:
      return "staging";

    default:
      return "development";
  }
}