import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../../scss/ebook-complete-dialog.scss';
import ETLogo from '../../img/ET colored.svg'
import BoxiaLogo from '../../img/logo.png';

function EbookCompleteDialog({show, onHide, isEnglish}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="ebook-complete-dialog"
    >
      <Modal.Body className="content-container">
        <div className="logo-container">
          <img src={BoxiaLogo} alt="Boxia Partage" className="boxia-logo"/>
          <img src={ETLogo} alt="Explorator technology" className="et-logo"/>
        </div>
        <p>
          {
            isEnglish ? 
              <>Copyright © 2020 BoXia e-book reader <br/> Powered by <a href="https://explorator.ca">Explorator Technology</a></>
            :
              <>Copyright © 2020 BoXia e-book reader <br/> Propulsé par <a href="https://explorator.ca">Explorator Technology</a></>
          }
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button><a href="/">{isEnglish ? "Home" : "Accueil" }</a></Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EbookCompleteDialog;
