import React from 'react';
import '../scss/comic-of-the-day.scss';
import ComicParagraph from './comicOfTheDay/ComicParagraph';
import ComicImage from './comicOfTheDay/comicImage';
import mediumZoom from 'medium-zoom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FacebookProvider, Comments, Like } from 'react-facebook';
import { Link } from 'react-router-dom';

class ComicOfTheDay extends React.Component {

  constructor(prop) {
    super(prop);
    this.zoom = mediumZoom();
  }

  attachZoom = image => {
    this.zoom.attach(image)
  }

  getUpdatedURL = () => {
    const { comicIndex } = this.props;
    let searchParams = new URLSearchParams(window.location.search);

    if (!searchParams.has('comic-of-the-day')) {
      searchParams.set('comic-of-the-day', comicIndex); //Assuming latest is the last element
    }

    if (!searchParams.has('language')) {
      searchParams.set('language', 'en'); //default to English
    }

    return `${window.location.origin}/${searchParams.toString()}`;
  }

  render() {
    let pageURL = this.getUpdatedURL();

    const SwitchButtons = () => {
      switch (this.props.indexInComicArray) {
        case 0:
          return (
            <div className="switch-buttons">
              <Link to={'/?comic-of-the-day=' + (parseInt(this.props.comicIndex, 10) - 1) + '&language=' + this.props.language}>
                <div className="button" onClick={() => { this.props.updateComic(-1); }}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  {' '}Previous
                </div>
              </Link>
            </div>
          );
        case this.props.numberOfComicsInArray - 1:
          return (
            <div className="switch-buttons">
              <Link to={'placeholder'}>
                <div className="button">
                </div>
              </Link>
              <Link to={'/?comic-of-the-day=' + (parseInt(this.props.comicIndex, 10) + 1) + '&language=' + this.props.language}>
                <div className="button" onClick={() => { this.props.updateComic(1); }}>
                  Next{' '}
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Link>
            </div>
          );
        default:
          return (
            <div className="switch-buttons">
              <Link to={'/?comic-of-the-day=' + (parseInt(this.props.comicIndex, 10) - 1) + '&language=' + this.props.language}>
                <div className="button" onClick={() => { this.props.updateComic(-1); }}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  {' '}Previous
                    </div>
              </Link>
              <Link to={'/?comic-of-the-day=' + (parseInt(this.props.comicIndex, 10) + 1) + '&language=' + this.props.language}>
                <div className="button" onClick={() => { this.props.updateComic(1); }}>
                  Next{' '}
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Link>
            </div>
          );
      }
    }
    if (this.props.language === "en") {
      return (
        <div className="comic-of-the-day">
          <ComicParagraph title={this.props.comicOfTheDay.enTitle} description={this.props.comicOfTheDay.enDescription} />
          <ComicImage
            src={this.props.comicOfTheDay.enImg}
            alt="Comic of the day"
            zoom={this.zoom}
            background="#FFF"
          />
          <p className="comic-of-the-day-signature">
            Scenarios: Body Ngoy
          </p>
          <p className="comic-of-the-day-signature">
            Illustrations: Hicham Absa
          </p>
          <div className='facebook-force-to-stay-certain-width'>
            <FacebookProvider appId="2981401235418445">
              <SwitchButtons comicIndex={this.props.comicIndex} />
              <div className="comic-comments-facebook-container">
                <div className="comic-comments-facebook-like">
                  <Like href={pageURL} colorScheme="dark" showFaces={false} share />
                </div>
              </div>
              <hr></hr>
              <Comments href={pageURL} numPosts={1} />
            </FacebookProvider>
          </div>
          {/* <ComicComments/> */}
        </div>
      );
    } else {
      return (
        <div className="comic-of-the-day">
          <ComicParagraph title={this.props.comicOfTheDay.frTitle} description={this.props.comicOfTheDay.frDescription} />
          <ComicImage
            src={this.props.comicOfTheDay.frImg}
            alt="Comic of the day"
            zoom={this.zoom}
            background="#FFF"
          />
          <p className="comic-of-the-day-signature">
            Scénarios: Body Ngoy
          </p>
          <p className="comic-of-the-day-signature">
            Dessins: Hicham Absa
          </p>
          <FacebookProvider appId="2981401235418445">
            <SwitchButtons comicIndex={this.props.comicIndex} />
            <div className="comic-comments-facebook-container">
              <div className="comic-comments-facebook-like">
                <Like href={pageURL} colorScheme="dark" showFaces={false} share />
              </div>
            </div>

            <hr></hr>
            <Comments href={pageURL} numPosts={1} />
          </FacebookProvider>
          {/* <ComicComments/> */}
        </div>
      )
    }
  }
}

export default ComicOfTheDay;
