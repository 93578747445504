import React from 'react';

class ToolsSquare extends React.Component{
 
  constructor(prop){
    super(prop);
    this.clickedbutton = this.clickedbutton.bind(this);
  }
  clickedbutton(){
    window.location.href = this.props.link;
  }
  render(){
    return (
      <div className="tools-page-square">
          <div className="tools-page-square-container">
            <img className="tools-page-square-image" src={this.props.img}></img>
            <p className="tools-page-square-title">{this.props.title}</p>
            <p className="tools-page-square-text">{this.props.text}</p>
            <div className="tools-page-square-button-container"> 
              <button className="tools-page-square-button" type="submit" onClick={this.clickedbutton}>{this.props.download}</button>
            </div>
          </div>
      </div>
    );
  }
}

export default ToolsSquare;
