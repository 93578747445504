import React from "react";
import "../scss/nav-bar.scss";
import logoImg from "../../src/img/logo.png";
import { Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'

class NavBarMobile extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(prop) {
    super(prop);
  }
  showSettings (event) {
    event.preventDefault();

  }

  
  render() {
    let isEnglish = true;
    let language = 'en';
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('language')) {
      if (searchParams.get('language') == 'en') {
        isEnglish = true;
      } else {
        isEnglish = false;
      }
    } else {
      window.location.search = 'language=en';
    }
    if (isEnglish) {
      language = 'en';
    } else {
      language = 'fr';
    }


    return(
      <div>
        <div className="nav-bar-mobile">
          <div className="nav-bar-mobile-container">
            <div>
              <img id="logo-mobile" src={logoImg} className="nav-bar-logo" />
            </div>
            {/*}
            <div>
              <p id="logo-text-mobile">Partage</p>
            </div>*/}
          </div>
        </div>

      <div className="nav-bar-mobile-menu">
        <Menu right>
          <img id="logo-mobile-menu" src={logoImg} className="nav-bar-logo" />
          <a id="home" className="menu-item" href="/"> {isEnglish ? "Home" : "Accueil"}</a>
          <a id="home" className="menu-item" href="/shopping"> {isEnglish ? "Shop" : "Magasin"}</a>
          <a id="services" className="menu-item" href="/services">{isEnglish ? "Services" : "Service"}</a>
          <a id="tools" className="menu-item" href="/tools">{isEnglish ? "Tools" : "Outils"}</a>
          {/*<hr></hr>*/}
          <a id="about" className="menu-item" href="/about">{isEnglish ? "About" : "À propos"}</a>
          <a id="sponsors" className="menu-item" href="/sponsors">{isEnglish ? "Sponsors" : "Commanditaires"}</a>
          <a id="contact" className="menu-item" href="/contact">{isEnglish ? "Contact us" : "Contactez nous"}</a>
           {/*<hr></hr>*/}
          <a id="doantion" className="menu-item" href="https://donorbox.org/boxia-partage-donation?hide_donation_meter=true">{isEnglish ? "Donate" : "Faire un don"}</a>
          <a id="french" className="menu-item"  onClick={this.props.onClick}>{isEnglish ? "Français" : "English"}</a>
          {/*<a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a>*/}
        </Menu>
      </div>
      </div>
    );
  }
}

export default NavBarMobile;
