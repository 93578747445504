import React, { Component } from 'react'

class ComicImage extends Component {
  zoom = this.props.zoom.clone({
    background: this.props.background,
  })

  attachZoom = image => {
    this.zoom.attach(image)
  }

  render() {
    return (
      <div className="comic-of-the-day-image">
        <img src={this.props.src} alt={this.props.alt} ref={this.attachZoom} />
      </div>
    )
  }
}

export default ComicImage