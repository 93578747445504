import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import styled from 'styled-components';
import leftArrow from '../../img/left-arrow.svg';
import rightArrow from '../../img/right-arrow.svg';
import Cookies from "js-cookie";
import { getApiUrl } from '../../Util';
import Spinner from 'react-spinner-material';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus, faUndo, faExpand, faCompress, faBookmark } from '@fortawesome/free-solid-svg-icons';
import LoginPanel from '../LoginPanel';
import EbookCompleteDialog from './ebookCompleteDialog';
import BookShelf from "./bookShelf";
import '../../scss/ereader.scss';

const MainBody = styled(Container)`
`;

const Page = styled(Col)`
  border-radius: .25em;
  justify-content: center;
  text-align: center;

  &::-webkit-scrollbar {
    width: 10px;
  }


  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius:10px;
  }


  &::-webkit-scrollbar-thumb {
    background: #C1C1C1; 
    border-radius:10px;
  }


  &::-webkit-scrollbar-thumb:hover {
    background: #C1C1C1; 
  }

  img{
    height: 600px; 
    width: auto;

    &.zoomed{
      height: 100%;
      width: auto;
    }
  }

  @media(max-width: 991px){
    margin-bottom: 1em;
    margin-top: 2em;
  }
`;

const PageButton = styled.div`
  border-radius: 100%;
  height: 52px;
  width: 52px;
  background-color: #f5f5f5;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.5);
  border: 1px solid #f8f8f8;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media(min-width: 500px){
    &:hover{
      box-shadow: none !important; 
    }
  }
`;

const PageButtonColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width: 500px){
    width: 100%;
    justify-content: space-evenly;
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  z-index: 99;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
`;

const Card = styled(Col)`
  margin-right:25px;
  cursor: pointer;

  img{
    width:100%;
    height:auto;
  }
`;

const PreviewCardsRow = styled(Row)`
  width: 160%;
  margin-bottom:10px;
  justify-content: center;
  align-items: center;
  background-color: rgb(128 128 128 / 0.6);
  padding: 1em;

  @media(max-width: 991px){
    display:none;
  }
`;

const CardsContainer = styled(Container)`
  @media(max-width: 991px){
    display:none;
  }
  
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }


  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }


  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

const Divider = styled.hr`
  @media(max-width: 991px){
    display:none;
  }
`;

const ComicZoomControllerButton = styled(Button)`
  margin: 1em;
`;

function EbookReader() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [availableEbooks, setAvailableEbooks] = useState([]);
  const [ebookPages, setEbookPages] = useState([]);
  const [isHeroImageLoading, setIsHeroImageLoading] = useState(false);
  const [isInReadingMode, setIsInReadingMode] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [comicPageNumber, setComicPageNumber] = useState(0);
  const [isEnglish, setIsEnglish] = useState(true);
  const [showBookShelf, setShowBookShelf] = useState(true);

  const comicFetchQuery = `
    query {
      allEbooks{
        id
        displayName
        language
        coverImageUrl
        ebookPages {
          imageUrl
          previewImageUrl
          pageNumber
        }
      }
    }  
  `;

  useEffect(() => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    const token = Cookies.get("auth-token");
    if(token != undefined && token != "undefined"){
      fetch(
        `${getApiUrl()}/graphql`, 
        {
          method: "POST",
          headers: { "Content-Type": "application/json", authorization: `Bearer ${token}` },
          body: JSON.stringify({ query: comicFetchQuery }),
          mode: 'cors'
        }
      )
      .then(res => res.json())
      .then(result => {
        setAvailableEbooks(result.data.allEbooks);
        setEbookPages(result.data.allEbooks[0].ebookPages); 
        setIsLoading(false);
        setIsLoggedIn(true);
      })
      .catch(error => setIsLoggedIn(false))
    }
  }, []);

  // Detect Language
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('language')) {
      if (searchParams.get('language') == 'en') {
        setIsEnglish(true);
      } else {
        setIsEnglish(false)
      }
    }
  }, []);

  const eBookOnSelect = (ebookId) => {
    setShowBookShelf(false);
    setEbookPages(availableEbooks.find(ebook => ebook.id == ebookId).ebookPages);
    setComicPageNumber(0);
    setIsHeroImageLoading(true);
  };

  const OnPrevClick = () => {
    if(comicPageNumber == 0){
      setComicPageNumber(ebookPages.length-1);
    }else{
      setComicPageNumber(comicPageNumber - 1);
      setIsHeroImageLoading(true);
    }
  };

  const OnNextClick = () => {
    if(comicPageNumber == ebookPages.length - 1){
      setComicPageNumber(0);
      setShowCompleteModal(true);
    }else{
      setComicPageNumber(comicPageNumber + 1);
      setIsHeroImageLoading(true);
    }
  };

  const previewPageOnClick = (pageNumber) => {
    setIsHeroImageLoading(true);
    setComicPageNumber(pageNumber);
  }

  const onWheel = e => {
    e.preventDefault();
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;

    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
    });
  };
    
  const scrollRef = useRef();

  const handleImgLoad = useCallback(() => {
    setIsHeroImageLoading(false);
  }, []);

  return (
    <MainBody fluid className={`e-reader-container ${isInReadingMode ? "reading-mode" : ""}`}>
      {
        !isLoggedIn ?
          <LoginPanel isEnglish={isEnglish}/>
        :
        !isLoading &&
          <>
            <BookShelf
              show={showBookShelf}
              onClose={() => setShowBookShelf(false)}
              availableBooks={availableEbooks}
              bookOnSelect={eBookOnSelect}
            />
            <Container>
              <Row className="justify-content-center">

                <PageButtonColumn md={1} className="d-none d-md-flex">
                  <PageButton onClick={()=>OnPrevClick()}> <img src={leftArrow} alt="Previous"/> </PageButton>
                </PageButtonColumn>

                <Page sm={12} md={10} className="p-0">
                  <SpinnerContainer>
                    <Spinner size={40} spinnerColor={"#aeaeae"} spinnerWidth={4} visible={isHeroImageLoading} />
                  </SpinnerContainer>

                  <TransformWrapper
                    defaultScale={1}
                  >
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <div>
                        <div className="tools">
                          <ComicZoomControllerButton>
                            <FontAwesomeIcon icon={faBookmark} onClick={() =>{ setShowBookShelf(true); setIsInReadingMode(false); }}/>
                          </ComicZoomControllerButton>
                          <ComicZoomControllerButton onClick={zoomIn} variant="light">
                            <FontAwesomeIcon icon={faSearchPlus} />
                          </ComicZoomControllerButton>
                          <ComicZoomControllerButton onClick={zoomOut} variant="light">
                            <FontAwesomeIcon icon={faSearchMinus} />
                          </ComicZoomControllerButton>
                          <ComicZoomControllerButton onClick={resetTransform} variant="light">
                            <FontAwesomeIcon icon={faUndo} />
                          </ComicZoomControllerButton>
                          <ComicZoomControllerButton onClick={() => setIsInReadingMode(!isInReadingMode)} variant="light">
                            <FontAwesomeIcon icon={isInReadingMode ? faCompress : faExpand} />
                          </ComicZoomControllerButton>
                        </div>
                        <TransformComponent>
                          <div className={"ebook-image-container"}>
                            <img className={isInReadingMode ? "reading-mode" : ""} src={ebookPages[comicPageNumber].imageUrl} onLoad={handleImgLoad} />
                          </div>
                        </TransformComponent>
                      </div>
                    )}
                  </TransformWrapper>
                </Page>

                <PageButtonColumn sm={1} md={1}>
                  <div className="d-xs-block d-sm-none">
                    <PageButton onClick={()=>OnPrevClick()}> <img src={leftArrow} alt="Previous"/> </PageButton>
                  </div>
                  <PageButton onClick={()=>OnNextClick()}> <img src={rightArrow} alt="Next"/> </PageButton>
                </PageButtonColumn>
              </Row>
            </Container>

            {
              !isInReadingMode &&
              <>
                <Divider/>

                <CardsContainer fluid>
                  <PreviewCardsRow
                    ref={scrollRef}
                    onWheel = {onWheel}
                  >
                    {ebookPages.map((page, index)=>
                      <Card 
                        key={page.pageNumber}
                        style={{
                          border: index == comicPageNumber ? "2px solid black" : "none",
                          borderRadius: "2px",
                          transition: "border 0.1s linear 0s;"
                        }}
                        onClick={()=> previewPageOnClick(index)}
                        className="p-0"
                      >
                        <img src={page.previewImageUrl} alt="comic" className="img-fluid" />
                      </Card>
                    )}
                  </PreviewCardsRow>
                </CardsContainer>
              </>
            }
          </>
      }
      <EbookCompleteDialog 
        isEnglish={isEnglish} 
        show={showCompleteModal} 
        onHide={() => setShowCompleteModal(false)}
      />
    </MainBody>
  );
}

export default EbookReader
