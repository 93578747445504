import React from 'react';
import Slider from "react-slick";
import '../../scss/rightSideBar/right-side-events.scss';

class RightSideEvents extends React.Component {

  constructor(prop) {
    super(prop);
  }

  render() {
    var settings = {
      className: "event-slider",
      dots: true,
      infinite: true,
      slidesToShow: 1,
      arrows: false,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: false,
      autoplaySpeed: 2000
    };
    if (this.props.language === "en") {
      return (
        <div className='events'>
          <h2 className="right-side-events-header">Events</h2>
          <Slider {...settings}>
            {
              this.props.allEvents.map( event => (
                <div className="event-container">
                  <img className="right-side-events-image" src={event.img} alt={event.enTitle}></img>
                  <p className="right-side-events-text">{event.enTitle}</p>
                </div>
              ))
            }
          </Slider>
        </div>
      );
    } else {
      return (
        <div>
          <p className="right-side-events-header">Événements</p>
          <Slider {...settings}>
            {
              this.props.allEvents.map( event => (
                <div className="event-container">
                  <img className="right-side-events-image" src={event.img} alt={event.frTitle}></img>
                  <p className="right-side-events-text">{event.frTitle}</p>
                </div>
              ))
            }
          </Slider>
        </div>
      );
    }
  }
}

export default RightSideEvents;
