import React from "react";
import "../scss/nav-bar.scss";
import logoImg from "../../src/img/logo.png";
import { Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'

class NavBar extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(prop) {
    super(prop);
  }

  render() {
    let isEnglish = true;
    let language = 'en';
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('language')) {
      if (searchParams.get('language') == 'en') {
        isEnglish = true;
      } else {
        isEnglish = false;
      }
    } else {
      window.location.search = 'language=en';
    }
    if (isEnglish) {
      language = 'en';
    } else {
      language = 'fr';
    }
    return (
      <nav className="nav-bar" id="nav_bar">
        <div className="nav-bar-div" />
        <div className="nav-bar-div">
          <Link to={"/" + '?language=' + language}>
            <p className="nav-bar-button">
              {isEnglish ? "Home" : "Accueil"}
            </p>
          </Link>
        </div>
        <div className="nav-bar-div">
          <Link to={"/services" + '?language=' + language}>
            <p className="nav-bar-button">
              {isEnglish ? "Services" : "Service"}
            </p>
          </Link>
        </div>
        <div className="nav-bar-div">
          <Link to={"/tools" + '?language=' + language}>
            <p className="nav-bar-button">
              {isEnglish ? "Tools" : "Outils"}
            </p>
          </Link>
        </div>
        <div className="nav-bar-div">
          <Link to={"/shopping" + '?language=' + language}>
            <p className="nav-bar-button">
              {isEnglish ? "Shop" : "Magasin"}
            </p>
          </Link>
        </div>
        <div className="nav-bar-div logo-container">
          <Link to={"/" + '?language=' + language}>
            <img src={logoImg} className="nav-bar-logo" />
            <span>Partage</span>
          </Link>
        </div>

        <div className="nav-bar-div">
          <Link to={"/about" + '?language=' + language}>
            <p className="nav-bar-button">
              {isEnglish ? "About" : "À propos"}
            </p>
          </Link>
        </div>
        <div className="nav-bar-div">
          <Link to={"/sponsors" + '?language=' + language}>
            <p className="nav-bar-button">
              {isEnglish ? "Sponsors" : "Commanditaires"}
            </p>
          </Link>
        </div>
        <div className="nav-bar-div">
          <Link to={"/ebookreader" + '?language=' + language}>
            <p className="nav-bar-button">
              {isEnglish ? "EReader" : "Lecteur"}
            </p>
          </Link>
        </div>
        <div className="nav-bar-div">
          <Link to={"/contact" + '?language=' + language}>
            <p className="nav-bar-button">
              {isEnglish ? "Contact us" : "Contactez nous"}
            </p>
          </Link>
        </div>

        <div className="nav-bar-div">
          <p className="nav-bar-divider">|</p>
        </div>
        <div className="nav-bar-div">
          <p className="nav-bar-button language-selector" onClick={this.props.onClick}>
            {isEnglish ? "Fr" : "En"}
          </p>
        </div>
      </nav>
    );
  }
}

export default NavBar;
