import React from 'react';

// Image assets
import theCanadianDream from '../../img/shopping_page/the_canadian_dream.jpg'
import canadianDream1En from '../../img/shopping_page/canadian_comic_book/en/1.png';
import canadianDream2En from '../../img/shopping_page/canadian_comic_book/en/2.jpg';
import canadianDream3En from '../../img/shopping_page/canadian_comic_book/en/3.jpg';
import canadianDream1Fr from '../../img/shopping_page/canadian_comic_book/fr/1.jpg';
import canadianDream2Fr from '../../img/shopping_page/canadian_comic_book/fr/2.jpg';
import canadianDream3Fr from '../../img/shopping_page/canadian_comic_book/fr/3.jpg';

import leCanadienImage from '../../img/shopping_page/le_canadien.png';
import leCanadienImage1 from '../../img/shopping_page/le_canadian/fr/1.png';
import leCanadienImage2 from '../../img/shopping_page/le_canadian/fr/2.png';
import leCanadienImage3 from '../../img/shopping_page/le_canadian/fr/3.png';

import theCanadianEn from '../../img/shopping_page/the_canadian/en.jpg';
import theCanadianFr from '../../img/shopping_page/the_canadian/fr.jpg';

import kassavCover from '../../img/shopping_page/kassav.jpeg';

import eclatempsCover from '../../img/shopping_page/eclatemps.jpg';

const availableItems = {
  en: {
    canadianComicBook: {
      title: "Comic Book The Canadian Dream: Canadian Values",
      priceComponent: (<b>$20</b>),
      previewImage: theCanadianDream,
      description: "It highlights different values ​​and characteristics common to the Canadian identity, thereby creating identity references and showcasing Canadians’ reputation to people from other parts of the world. In addition, this book features topics such as the Black History Month, Canada in the world, etc.",
      itemImages: [canadianDream1En, canadianDream2En, canadianDream3En]
    },
    theCanadian: {
      title: "Comic Book 'The Canadian'",
      priceComponent: (<b>$25</b>),
      previewImage: theCanadianEn,
      description: "THE CANADIAN chronicles the story of a Canadian father of African descent teaching his teenage daughter Black History, while highlighting some First Nations values. This intriguing conversation happens while they explore 6 Canadian cities (Ottawa, Montréal, Halifax, Vancouver, Calgary, Toronto) for 6 days.",
      itemImages: [theCanadianEn]
    }
  },
  fr: {
    canadianComicBook: {
      title: "Bande dessinée ‘Le Rêve canadien : Valeurs canadiennes",
      priceComponent: (<b>$20</b>),
      previewImage: theCanadianDream,
      description: "Elle met en évidence les valeurs et caractéristiques identitaires communes à l’ensemble de la population canadienne d’Est à l’Ouest du pays, créant ainsi des références identitaires et des réputations des Canadiennes et Canadiens auprès des autres peuples et nations du monde. En outre, elle fait un clin d’œil sur les sujets tels que le Mois de l'Histoire des Noirs, le Canada dans le monde, etc.",
      itemImages: [canadianDream1Fr, canadianDream2Fr, canadianDream3Fr]
    },
    leCanadian: {
      title: "Bande dessinée Le Rêve canadien",
      priceComponent: (<b>$16</b>),
      previewImage: leCanadienImage,
      description: "Une bande dessinée produite en 2015. Le Rêve canadien retrace le parcours d’un nouvel arrivant francophone en Ontario en début des années 90. Il s’appelle Patcho. Son histoire au Canada commence à Toronto et se poursuit à Ottawa. Toutes les étapes du processus d’Immigration, d’établissement et d’intégration dans la collectivité franco-ontarienne sont mises en exergue.",
      itemImages: [leCanadienImage1, leCanadienImage2, leCanadienImage3]
    },
    theCanadian: {
      title: "Bande dessinée ‘Le Canadien'",
      priceComponent: (<b>$25</b>),
      previewImage: theCanadianFr,
      description: "La bande dessinée Le CANADIEN relate une conversation entre un père et sa fille adolescente, issus des communautés afro descendantes canadiennes, sur l'histoire des Noirs au Canada, tout en soulignant quelques valeurs des premières nations. Cette fameuse conversation dure pendant 6 jours dans 6 villes du Canada (Ottawa, Montréal, Halifax, Vancouver, Calgary, Toronto).",
      itemImages: [theCanadianFr]
    },
    kassav: {
      title: "KASSAV’ : DES TRADITIONS À L’UNIVERSALITÉ",
      priceComponent: (<b>$27.95</b>),
      previewImage: kassavCover,
      description: "« Kassav’ : Des Traditions à l’universalité » avec un sous-titre « N’est-ce pas une manière de bâtir des communautés et des sociétés respectables ? ». C’est à la fois une réflexion et une démarche socioculturelles et scientifiques qui utilisent l’héritage culturel du parcours exemplaire du groupe mythique Kassav’ dans une allégorie appliquée dans les réalités des communautés et des sociétés modernes.",
      itemImages: [kassavCover]
    },
    eclamtemps: {
      title: "ECLATEMPS ! - HISTOIRES FRANCOS EN ONTARIO",
      priceComponent: (<b>$20.00</b>),
      description: "Cing jeunes Francos de I'Ontario aux diverses origines sont a la quéte d'une force mystérieuse. Ces explorateurs modernes sont plongés dans le feu de I'action a travers le temps et I'espace... De la colonie de Détroit-Windsor jusqu’aux mines du Nord, et du Fort William a Thunder Bay jusqu’au moulin de Hawkesbury, ils font la rencontre de personnages fascinants et leur viennent en aide pour écrire I'histoire. Elles et ils découvriront des valeurs précieuses qui unissent tous les Franco-Ontariens et Franco-Ontariennes.",
      previewImage: eclatempsCover,
      itemImages: [eclatempsCover]
    }
  }
}

export { availableItems }
