import React from 'react';
import '../scss/shopping-page.scss';

import PageHeaderMobile from './PageHeaderMobile';
import PopulatedItemRow from './shopping_page/populatedItemRow';
import OrderPopup from './shopping_page/orderPopup';
import ShippingInformationForm from './shopping_page/shippingForm';
import OrderSummary from './shopping_page/orderSummary';
import OrderSubmissionResultPopup from './shopping_page/submissionResultPopup';
import EbookShoppingSection from './shopping_page/ebookShoppingSection';

import axios from "axios";
import { getApiUrl } from '../Util';

import { availableItems } from './shopping_page/items';
// Image assets
import headerImage from '../img/shopping_page/header.png';

class ShoppingPage extends React.Component {
  state = {
    orderSummaryOpen: false,
    orderPopupOpen: false,
    popupPreviewImages: [],
    popupTitle: "",
    popupPricing: null,
    popupDescription: "",
    popupShippingMessage: "",
    shippingFormOpen: false,

    submissionPopupOpen: false,
    successSubmission: true,

    shippingInfo: {
      name: "N/A",
      address: "N/A",
      city: "N/A",
      province: "N/A",
      postalCode: "N/A",
      phoneNumber: "N/A",
      email: "N/A"
    },
    shippingInfoErrorMessage: null,
    orderQuantities: {
      canadianComicBook: null,
      leCanadian: null,
      theCanadian: null,
      kassav: null,
      eclamtemps: null    
    }
  };

  submitOrder = (isEnglish) => {
    const {
      shippingInfo,
      orderQuantities
    } = this.state;

    axios
      .post(`${getApiUrl()}/order`, {
        buyer_name: shippingInfo.name,
        buyer_address: shippingInfo.address,
        buyer_city: shippingInfo.city,
        buyer_province: shippingInfo.province,
        buyer_postal_code: shippingInfo.postalCode,
        buyer_phone_number: shippingInfo.phoneNumber,
        buyer_email: shippingInfo.email,
        
        canadian_comic_book_quantity: orderQuantities.canadianComicBook || 0,
        le_canadian_quantity: orderQuantities.leCanadian || 0,
        the_canadian_quantity: orderQuantities.theCanadian || 0,
        kassav_quantity: orderQuantities.kassav || 0,
        eclamtemps_quantity: orderQuantities.eclamtemps || 0,

        is_english: isEnglish
      })
      .then(response => {
        this.setState({
          submissionPopupOpen: true,
          successSubmission: true
        })
      })
      .catch(error => {
        this.setState({
          submissionPopupOpen: true,
          successSubmission: false
        })
      });
  }

  orderPopupCloseButtonOnClick = () => {
    this.setState({
      orderPopupOpen: false
    });
  }

  isEmail = (email = null) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  
    return regex.test(email);
    
  }

  shippingContinueButtonOnClick = (isEnglish) => {
    const { shippingInfo } = this.state;
    let validationMessage = null;

    // Form validation 
    if(Object.values(shippingInfo).includes('N/A')){
      validationMessage = isEnglish ? "Please fill in all fields." : "Merci de remplir tous les champs.";
    }else if(!this.isEmail(shippingInfo.email)){
      validationMessage = isEnglish ? "Please enter a valid email address." : "S'il vous plaît, mettez une adresse email valide."
    }

    this.setState({
      orderSummaryOpen: validationMessage === null ? true : false,
      shippingInfoErrorMessage: validationMessage
    })
  }

  submissionResultOnClose = () => {
    const {
      successSubmission
    } = this.state;

    if(successSubmission){
      window.location.reload();
    }else{
      this.setState({
        submissionPopupOpen: false
      })
    }
  }

  shippingInfoOnChange = (fieldName, fieldContent) => {
    this.setState({
      shippingInfo: {
        ...this.state.shippingInfo,
        [fieldName]: fieldContent
      }
    });
  }

  imageOnClick = (itemName, isEnglish) => {
    const itemContent = isEnglish ? availableItems.en[itemName] : availableItems.fr[itemName]

    this.setState({
      orderPopupOpen: true,
      popupPreviewImages: itemContent.itemImages,
      popupTitle: itemContent.title,
      popupPricing: itemContent.priceComponent,
      popupDescription: itemContent.description,
      popupShippingMessage: isEnglish ? "Free shipping included" : "Livraison incluse"
    })
  }

  buyButtonOnClick = (itemName) => {
    this.setState({
      shippingFormOpen: true,
      orderQuantities: {
        ...this.state.orderQuantities,
        [itemName]: 1
      }
    })
  }

  quantityOnChange = (itemName, quantity) => {
    this.setState({
      orderQuantities: {
        ...this.state.orderQuantities,
        [itemName]: quantity
      }
    })
  }

  render() {
    const { 
      orderPopupOpen, 
      popupPreviewImages, 
      popupTitle, 
      popupPricing, 
      popupDescription, 
      popupShippingMessage,
      shippingFormOpen,
      orderQuantities,

      orderSummaryOpen,
      shippingInfoErrorMessage,
      shippingInfo,

      submissionPopupOpen,
      successSubmission,
    } = this.state;

    let isEnglish = true;
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('language')) {
      isEnglish = searchParams.get('language') === 'en' ? true : false;
    } else {
      window.location.search = 'language=en';
    }
    
    const availableItemsToRender = isEnglish ? availableItems.en : availableItems.fr;

    return (
      <React.Fragment>
        <div className="shopping-page">
          <PageHeaderMobile image={headerImage} text={isEnglish ? "Shop" : "Achat"} />
          <div className="shopping-page-container">
            { 
              Object.keys(availableItemsToRender).map( itemName => {
                return (
                  itemName &&
                  <PopulatedItemRow 
                    key={itemName}
                    itemName={itemName}
                    isEnglish={isEnglish}
                    orderQuantity={orderQuantities[itemName]}
                    imageOnClick={(itemName) => this.imageOnClick(itemName, isEnglish)}
                    buyButtonOnClick={() => this.buyButtonOnClick(itemName)}
                    quantityOnChange={(quantity) => this.quantityOnChange(itemName, quantity) }
                  />
              )
            })
            }
          </div>
          <EbookShoppingSection isEnglish={isEnglish}/>

          {/* Shipping information form */}
          {
            shippingFormOpen && 
            <>
              <hr/>
              <ShippingInformationForm
                isEnglish={isEnglish}
                fieldOnChange={this.shippingInfoOnChange}
                continueOnClick={() => this.shippingContinueButtonOnClick(isEnglish)}
                validationMessage={shippingInfoErrorMessage}
              />
            </>
          }
        </div>

        {/* Order Popup Modal */}
        <OrderPopup 
          open={orderPopupOpen} 
          onClose={this.orderPopupCloseButtonOnClick}
          previewImages={popupPreviewImages}
          title={popupTitle}
          description={popupDescription}
          pricingComponent={popupPricing}
          shippingMessage={popupShippingMessage}
        />

        {/* Order Summary Modal */}
        <OrderSummary
          open={orderSummaryOpen}
          isEnglish={isEnglish}
          shippingInfo={shippingInfo}
          canadianComicBookQuantity={orderQuantities.canadianComicBook}
          leCanadianQuantity={orderQuantities.leCanadian}
          theCanadianQuantity={orderQuantities.theCanadian}
          kassavQuantity={orderQuantities.kassav}
          eclatempsQuantity={orderQuantities.eclamtemps}
          shippingInfoChangeButtonOnClick={() => this.setState({orderSummaryOpen: false})}
          confirmButtonOnClick={() => this.submitOrder(isEnglish)}
          orderItemComponents={
            [
              `${orderQuantities.canadianComicBook !== null ? 'canadianComicBook' : ''}`,
              `${!isEnglish && orderQuantities.leCanadian !== null ? 'leCanadian' : ''}`,
              `${orderQuantities.theCanadian !== null ? 'theCanadian' : ''}`,
              `${!isEnglish && orderQuantities.kassav !== null ? 'kassav' : ''}`,
              `${!isEnglish && orderQuantities.eclamtemps !== null ? 'eclamtemps' : ''}`
            ].map( itemName => (
              itemName !== '' &&
              <PopulatedItemRow
                key={"orderpopup-"+itemName}
                itemName={itemName}
                isEnglish={isEnglish}
                orderQuantity={orderQuantities[itemName]}
                imageOnClick={(itemName) => this.imageOnClick(itemName, isEnglish)}
                buyButtonOnClick={() => this.buyButtonOnClick(itemName)}
                quantityOnChange={(quantity) => this.quantityOnChange(itemName, quantity) }
              />
            ))
          }
        />

        {/* Order Submission Result Modal */}
        <OrderSubmissionResultPopup
          open={submissionPopupOpen}
          isEnglish={isEnglish}
          isSuccess={successSubmission}
          onClose={this.submissionResultOnClose}
        />
      </React.Fragment>
    );
  }
}

export default ShoppingPage;
