import React from 'react';
import '../../scss/comicOfTheDay/comic-paragraph.scss';

class ComicParagraph extends React.Component{
  // eslint-disable-next-line no-useless-constructor
  constructor(prop){
    super(prop);
  }

  render(){
    return (
      <div className="comic-paragraph">
        <p className="comic-paragraph-title">
          {this.props.title}{' '}
          <span className="comic-paragraph-text">"{this.props.description}"</span>
        </p>
        <hr></hr>
      </div>
    );
  }
}

export default ComicParagraph;
