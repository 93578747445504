import React from 'react';

class SponsorsDropdown extends React.Component{
 
  constructor(prop){
    super(prop);
  }

  render(){
    const { optionArray=[], onChange, isEnglish } = this.props;

    return (
      <div className="sponsors-page-dropdown">
          <h3>{this.props.header}</h3>
          <select onChange={(e) => onChange(e.target.value)} >
            <option value="not-selected">{ isEnglish ? "Choose an option" : "Choisissez une option" }</option>
            {
              optionArray.map( option => <option key={option} value={option}>{option}</option>)
            }
          </select>
      </div>
    );
  }
}

export default SponsorsDropdown;
