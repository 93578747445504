import React from 'react';
import '../../scss/shopping_page/order-summary.scss';
import Modal from 'react-responsive-modal';

const SubmissionResultPopup = ({ 
  open,
  isEnglish,
  isSuccess,

  onClose
}) => {
  
  return (
    <Modal open={open} focusTrapped={false} onClose={onClose} center>
      <div id="submission-result-container">
        <h2 className="modal-title">
          {
            isEnglish ? 
              isSuccess ? 
                'Thank you for your order'
              :
                'Unable to submit your order at the moment'
            : 
              isSuccess ? 
                'Merci pour votre achat'
              :
                'Impossible de soumettre votre commande pour le moment'
          }
        </h2>
        <div id="submission-result-description">
          {
            isEnglish ? 
              isSuccess ? 
                'We will confirm your order via email once the E-transfer has been processed.'
              :
                'Please try again later or email irene@boxia.ca for support'
            : 
              isSuccess ? 
                'Nous confirmerons votre commande via courriel aussitôt que le virement Interac est bien complété.'
              :
                "Veuillez réessayer plus tard ou envoyer un courriel à irene@boxia.ca pour obtenir de l'aide"
          }
        </div>
      </div>
    </Modal>
  )
}
export default SubmissionResultPopup;