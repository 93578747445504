import React from 'react';
import '../../scss/rightSideBar/right-side-bar.scss';
import WordOfContainer from './WordOfContainer';
import RightSideBoard from './RightSideBoard';
import RightSideEvents from './RightSideEvents';
import RightSideDonation from './RightSideDonation';
import MediaQuery from 'react-responsive';

class RightSideBar extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    const wordOfLove = this.props.wordsOfLove[0];
    const wordOfWisdom = this.props.wordsOfWisdom[0];
    const culturalBillboard = this.props.culturalBillboard ? this.props.culturalBillboard[0] : {};

    if (this.props.language === "en") {
      return (
        <div className="right-side-bar">
          <MediaQuery query="(max-device-width: 1040px)">
            <RightSideDonation language={this.props.language} />
            <div className='words_container'>
              <WordOfContainer 
                header="Word of love" 
                content={wordOfLove.enContent} 
                author={wordOfLove.author}
                by={wordOfLove.by}
              />
              <WordOfContainer 
                header="Word of wisdom" 
                content={wordOfWisdom.enContent}
                author={wordOfWisdom.author}
                by={wordOfWisdom.by} 
              />
            </div>
            <div className='billboard_events_container'>
              <RightSideBoard language={this.props.language} culturalBillboard={culturalBillboard}/>
              <RightSideEvents language={this.props.language} allEvents={this.props.allEvents} />
            </div>
          </MediaQuery>
          <MediaQuery query="(min-device-width: 1040px)">
            <RightSideDonation language={this.props.language} />
            <WordOfContainer 
              header="Word of love" 
              content={wordOfLove.enContent} 
              author={wordOfLove.author}
              by={wordOfLove.by}
            />
            <WordOfContainer 
              header="Word of wisdom" 
              content={wordOfWisdom.enContent}
              author={wordOfWisdom.author}
              by={wordOfWisdom.by} 
            />
            <RightSideBoard language={this.props.language} culturalBillboard={culturalBillboard}/>
            <RightSideEvents language={this.props.language} allEvents={this.props.allEvents} />
          </MediaQuery>
        </div>
      );
    } else {
      return (
        <div className="right-side-bar">
          <MediaQuery query="(max-device-width: 1040px)">
            <RightSideDonation language={this.props.language} />
            <WordOfContainer 
              header="Mots d’amour" 
              content={wordOfLove.frContent} 
              author={wordOfLove.author}
              by={wordOfLove.by}
            />
            <WordOfContainer 
              header="Mots de sagesse" 
              content={wordOfWisdom.frContent} 
              author={wordOfWisdom.author}
              by={wordOfWisdom.by}
            />
            <RightSideBoard language={this.props.language} culturalBillboard={culturalBillboard}/>
            <RightSideEvents language={this.props.language} allEvents={this.props.allEvents} />
          </MediaQuery>
          <MediaQuery query="(min-device-width: 1040px)">
            <RightSideDonation language={this.props.language} />
            <WordOfContainer 
              header="Mots d’amour" 
              content={wordOfLove.frContent} 
              author={wordOfLove.author}
              by={wordOfLove.by}
            />
            <WordOfContainer 
              header="Mots de sagesse" 
              content={wordOfWisdom.frContent} 
              author={wordOfWisdom.author}
              by={wordOfWisdom.by}
            />
            <RightSideBoard language={this.props.language} culturalBillboard={culturalBillboard}/>
            <RightSideEvents language={this.props.language} allEvents={this.props.allEvents} />
          </MediaQuery>
        </div >
      );
    }
  }
}

export default RightSideBar;
