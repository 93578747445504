import React from 'react';
import '../scss/content.scss';
import ServicesPage from './ServicesPage';
import HomePage from './HomePage';
import AboutPage from './about_page/about_page';
import ContactPage from './ContactUsPage';
import ToolsPage from './ToolsPage';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import SponsorsPage from './SponsorsPage';
import PrivacyPage from './PrivacyPage';
import ShoppingPage from './ShoppingPage';
import EbookReader from './ebookreader';
import OrderConfirmation from './ebookreader/orderConfirmation';

class Content extends React.Component {

  render() {
    const { allComicOfTheDay } = this.props.data;
    return (
      <div>
        <Switch>
          <Route exact path='/' component={() => {
            const { allComicOfTheDay } = this.props.data;
            let searchParams = new URLSearchParams(window.location.search);
            let comicID = 0;
            let comicIndex = -1;
            let language = 'en';
            if (!searchParams.has('comic-of-the-day')) {
              window.location.search = window.location.search + '&' + 'comic-of-the-day=' + allComicOfTheDay[0].id;
              comicID = allComicOfTheDay[0].id;
              comicIndex = 0;
            } else {
              comicID = searchParams.get('comic-of-the-day');
              for (let x = 0; x < allComicOfTheDay.length; x++) {
                if (comicID == allComicOfTheDay[x].id) {
                  comicIndex = x;
                }
              }
              if (comicIndex == -1) {
                comicIndex = 0;
              }
            }
            if (!searchParams.has('language')) {
              window.location.search = window.location.search + '&' + 'language=en';
              language = 'en';
            } else {
              if (searchParams.get('language') == 'en') {
                language = 'en';
              } else {
                language = 'fr';
              }
            }
            return (<HomePage language={this.props.language} data={this.props.data} language={language} comicID={comicID} comicIndex={comicIndex} />);
          }} />
          <Route exact path='/about' component={() => <AboutPage language={this.props.language} />} />
          <Route exact path='/services' component={() => <ServicesPage language={this.props.language} />} />
          <Route exact path='/tools' component={() => <ToolsPage language={this.props.language} />} />
          <Route exact path='/sponsors' component={() => <SponsorsPage language={this.props.language} />} />
          <Route exact path='/contact' component={() => <ContactPage language={this.props.language} />} />
          <Route exact path='/privacy' component={() => <PrivacyPage language={this.props.language} />} />
          <Route exact path='/shopping' component={() => <ShoppingPage language={this.props.language} />} />
          <Route exact path='/ebookreader' component={() => <EbookReader language={this.props.language}/>}/>
          <Route exact path='/ebookOrderSuccess' component={() => <OrderConfirmation language={this.props.language} success={true} />}/>
          <Route exact path='/ebookOrderFail' component={() => <OrderConfirmation language={this.props.language} success={false} />}/>
          <Route component={() => { return (<HomePage language={this.props.language} data={this.props.data} />); }} />
        </Switch>
      </div >
    );
  }
}

export default Content;
