import React from 'react';
import '../scss/page-header.scss';

class PageHeaderMobile extends React.Component{
 
  constructor(prop){
    super(prop);
  }

  render(){
    const { image, position } = this.props;

    return (
    <div className="page-header-container-mobile" 
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: position || "bottom"
      }}> 
      <h1 className ="page-header-text">{this.props.text}</h1>
    </div>
    );
  }
}

export default PageHeaderMobile;
