import React from 'react';

class SponsorsCard extends React.Component{
 
  constructor(prop){
    super(prop);
  }

  render(){
    const isEnglish = this.props.language === "en";
      if(this.props.tier === 1){
        return (
        <div className="sponsors-page-card">
            <div className="sponsors-page-card-text">
                <p className="sponsors-page-card-number" id="sponsors-page-card-tier-1">1</p>
                <p className="sponsors-page-card-month">{isEnglish ? "Month" : "Mois"}</p>
                <p className="sponsors-page-card-money">$200</p>
            </div>
        </div>
        );
      }else if(this.props.tier === 2){

        return (
            <div className="sponsors-page-card">
                <div className="sponsors-page-card-text">
                    <p className="sponsors-page-card-number" id="sponsors-page-card-tier-2">3</p>
                    <p className="sponsors-page-card-month">{isEnglish ? "Month" : "Mois"}</p>
                    <p className="sponsors-page-card-money">$500</p>
                </div>
            </div>
          );
      }else{
        return (
            <div className="sponsors-page-card">
                <div className="sponsors-page-card-text">
                    <p className="sponsors-page-card-number" id="sponsors-page-card-tier-3">3+</p>
                    <p className="sponsors-page-card-month">{isEnglish ? "Month" : "Mois"}</p>
                    <p className="sponsors-page-card-money">$750</p>
                </div>
            </div>
          );
      }
  }
}

export default SponsorsCard;
