import React from "react";
import "../scss/sponsors-page.scss";
import PageHeader from "./PageHeader";
import SponsorsPackage from "./SponsorsPackage";
import SponsorsCard from "./SponsorsCard";
import SponsorsQuestion from "./SponsorsQuestion";
import SponsorsDropdown from "./SponsorsDropdown";
import axios from "axios";
import { Link } from "react-router-dom";
import PageHeaderMobile from "./PageHeaderMobile";
import MediaQuery from "react-responsive";
import { getApiUrl } from '../Util';


class SponsorsPage extends React.Component {
  constructor(prop) {
    super(prop);

    this.state = {
      sponsoringFormName: "",
      sponsoringFormEmail: "",
      sponsoringFormPhone: "",
      sponsoringFormAvailability: "",
      sponsoringFormPackage: "",
      sponsoringFormMessage: "",

      allowSubmission: false,
      contactFormSubmitting: false,
      successSubmission: null
    };

    this.checkIsReadyForSubmission = this.checkIsReadyForSubmission.bind(this);
    this.getSponsoringForm = this.getSponsoringForm.bind(this);
  }

  getHourOptions(isEnglish) {
    if (isEnglish) {
      return [
        "8am-9am",
        "9am-10am",
        "10am-11am",
        "11am-12pm",
        "12pm-1pm",
        "1pm-2pm",
        "2pm-3pm",
        "3pm-4pm",
        "4pm-5pm",
        "5pm-6pm",
        "6pm-7pm",
        "7pm-8pm"
      ];
    } else {
      return [
        "8h-9h",
        "9h-10h",
        "10h-11h",
        "11h-12h",
        "12h-13h",
        "13h-14h",
        "14h-15h",
        "15h-16h",
        "16h-17h",
        "17h-18h",
        "18h-19h",
        "19h-20h"
      ];
    }
  }

  getPackageOptions(isEnglish) {
    if (isEnglish) {
      return ["Bronze", "Silver", "Gold", "Platinum"];
    } else {
      return ["Bronze", "Argent", "Or", "Platine"];
    }
  }

  checkIsReadyForSubmission() {
    const {
      sponsoringFormName,
      sponsoringFormEmail,
      sponsoringFormPhone,
      sponsoringFormAvailability,
      sponsoringFormPackage,
      sponsoringFormMessage
    } = this.state;

    if (
      sponsoringFormName !== "" &&
      sponsoringFormEmail !== "" &&
      sponsoringFormPhone !== "" &&
      sponsoringFormAvailability !== "" &&
      sponsoringFormPackage !== "" &&
      sponsoringFormMessage !== ""
    ) {
      this.setState({
        allowSubmission: true
      });
    }
  }

  submitOnClick() {
    const {
      sponsoringFormName,
      sponsoringFormEmail,
      sponsoringFormPhone,
      sponsoringFormAvailability,
      sponsoringFormPackage,
      sponsoringFormMessage
    } = this.state;

    this.setState({ contactFormSubmitting: true });

    axios
      .post(`${getApiUrl()}/sponsoring_contact`, {
        sender_name: sponsoringFormName,
        sender_email: sponsoringFormEmail,
        phone_number: sponsoringFormPhone,
        message: sponsoringFormMessage,
        availability: sponsoringFormAvailability,
        package_type: sponsoringFormPackage
      })
      .then(response => {
        this.setState({
          contactFormSubmitting: false,
          successSubmission: true
        });
      })
      .catch(error => {
        this.setState({
          contactFormSubmitting: false,
          successSubmission: false
        });
      });
  }

  getSponsoringForm(isEnglish) {
    const {
      allowSubmission,
      contactFormSubmitting,
      successSubmission
    } = this.state;

    return (
      <div className="sponsors-page-contact" id="donation_part_of_page">
        <div className="sponsors-page-contact-container">
          <p className="sponsors-page-services-container-header">
            {isEnglish
              ? "Become a Sponsor Now!"
              : "Devenez commanditaire maintenant!"}
          </p>
          <span className="contact-us-link-container">
            <Link to={`/contact?language=${isEnglish}`}>
              {isEnglish ? "Contact Us" : "Contactez nous"}
            </Link>
          </span>
          <form>
            <div className="sponsors-page-questions-wrapper">
              <div className="sponsors-page-questions-margin">
                <SponsorsQuestion
                  header={isEnglish ? "Your name" : "Nom" }
                  placeholder={isEnglish ? "Enter your name" : "Écrivez votre nom"}
                  onChange={value =>
                    this.setState(
                      { sponsoringFormName: value },
                      this.checkIsReadyForSubmission
                    )
                  }
                />
              </div>
              <div className="sponsors-page-questions-margin">
                <SponsorsQuestion
                  header={isEnglish ? "Email" : "Adresse courriel" }
                  placeholder={isEnglish ? "Enter your email address" : "Écrivez votre adresse courriel"}
                  onChange={value =>
                    this.setState(
                      { sponsoringFormEmail: value },
                      this.checkIsReadyForSubmission
                    )
                  }
                />
              </div>
              <div className="sponsors-page-questions-margin">
                <SponsorsQuestion
                  header={isEnglish ? "Phone" : "Numéro de téléphone" }
                  type="number"
                  placeholder={isEnglish ? "Enter your phone number" : "Écrivez votre numéro de téléphone"}
                  onChange={value =>
                    this.setState(
                      { sponsoringFormPhone: value },
                      this.checkIsReadyForSubmission
                    )
                  }
                />
              </div>
              <div className="sponsors-page-questions-margin">
                <SponsorsDropdown
                  isEnglish={isEnglish}
                  header={isEnglish ? "Availability for Phone Call" : "Disponibilité pour un premier appel téléphonique" }
                  optionArray={this.getHourOptions(isEnglish)}
                  onChange={value =>
                    this.setState(
                      { sponsoringFormAvailability: value },
                      this.checkIsReadyForSubmission
                    )
                  }
                />
              </div>
              <div className="sponsors-page-questions-margin">
                <SponsorsDropdown
                  isEnglish={isEnglish}
                  header={isEnglish ? "Package Type" : "Option de commandite " }
                  optionArray={this.getPackageOptions(isEnglish)}
                  onChange={value =>
                    this.setState(
                      { sponsoringFormPackage: value },
                      this.checkIsReadyForSubmission
                    )
                  }
                />
              </div>
              <div className="sponsors-page-questions-margin">
                <SponsorsQuestion
                  header={isEnglish ? "Message" : "Message" }
                  placeholder={isEnglish ? "Your message here" : "Écrivez votre message"}
                  onChange={value =>
                    this.setState(
                      { sponsoringFormMessage: value },
                      this.checkIsReadyForSubmission
                    )
                  }
                />
              </div>
            </div>

            <div className="submission-container">
              <div
                className={`sponsors-page-contact-submit ${!allowSubmission && "disable"}`}
                onClick={() => allowSubmission && this.submitOnClick()}
              >
              {
                isEnglish ? 
                  contactFormSubmitting ? "Submitting ... " : "Submit"
                :
                  contactFormSubmitting ? "Soumission ... " : "Soumettre"
              }
              </div>
              <div className="submission-message">
                {
                  isEnglish ?
                    successSubmission !== null &&
                      (successSubmission
                        ? "Thanks for reaching out, we will contact you shortly!"
                        : "We are experiencing some technical problem at the moment, please try again later.")
                  :
                    successSubmission !== null &&
                      (successSubmission
                        ? "Merci de nous avoir contacté, nous communiquerons avec vous sous peu."
                        : "Nous avons des problèmes techniques en ce moment, veuillez essayer de nouveau plus tard.")
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    let isEnglish = true;
    let language = "en";
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("language")) {
      if (searchParams.get("language") === "en") {
        isEnglish = true;
      } else {
        isEnglish = false;
      }
    } else {
      window.location.search = "language=en";
    }
    if (isEnglish) {
      language = "en";
    } else {
      language = "fr";
    }

    return (
      <div>
        <MediaQuery query="(max-device-width: 1178px)">
          <div className="sponsors-page-mobile">
            <PageHeaderMobile
              image="https://i.ibb.co/ZRsMnWg/sponsors.jpg"
              text={isEnglish ? "Sponsors" : "Commanditaires"}
              position="center"
            />

            <div className="sponsors-page-package-container">
              <p className="sponsors-page-package-container-header">
                {isEnglish ? "Sponsorship Packages" : "Plans de commandite"}
              </p>

              <MediaQuery query="(min-device-width: 992px)">
                <div className="sponsors-page-packages-wrapper">
                  <SponsorsPackage tier={1} language={language} />
                  <SponsorsPackage tier={2} language={language} />
                  <SponsorsPackage tier={3} language={language} />
                  <SponsorsPackage tier={4} language={language} />
                </div>
              </MediaQuery>
              <MediaQuery query="(max-device-width: 992px) and (min-device-width:576px)">
                <div className="sponsors-page-packages-wrapper">
                  <SponsorsPackage tier={1} language={language} />
                  <SponsorsPackage tier={2} language={language} />
                </div>
                <div className="sponsors-page-packages-wrapper">
                  <SponsorsPackage tier={3} language={language} />
                  <SponsorsPackage tier={4} language={language} />
                </div>
              </MediaQuery>

              <MediaQuery query="(max-device-width: 576px)">
                <div className="sponsors-page-packages-wrapper">
                  <SponsorsPackage tier={1} language={language} />
                </div>
                <div className="sponsors-page-packages-wrapper">
                  <SponsorsPackage tier={2} language={language} />
                </div>
                <div className="sponsors-page-packages-wrapper">
                  <SponsorsPackage tier={3} language={language} />
                </div>
                <div className="sponsors-page-packages-wrapper">
                  <SponsorsPackage tier={4} language={language} />
                </div>
              </MediaQuery>
            </div>

            <div className="sponsors-page-services-container">
              <p className="sponsors-page-services-container-header">
                {isEnglish ? "Advertising Services" : "Services publicitaires"}
              </p>
              <div className="sponsors-page-card-wrapper">
                <SponsorsCard tier={1} language={language} />
                <SponsorsCard tier={2} language={language} />
                <SponsorsCard tier={3} language={language} />
              </div>
            </div>

            {
              this.getSponsoringForm(isEnglish)
            }
          </div>
        </MediaQuery>

        <MediaQuery query="(min-device-width: 1179px)">
          <div className="sponsors-page">
            <PageHeader
              image="https://i.ibb.co/ZRsMnWg/sponsors.jpg"
              text={isEnglish ? "Sponsors" : "Commanditaires"}
              position="center"
            />
            <div className="sponsors-page-package-container">
              <p className="sponsors-page-package-container-header">
                {isEnglish ? "Sponsorship Packages" : "Plans de commandite"}
              </p>
              <div className="sponsors-page-packages-wrapper">
                <SponsorsPackage tier={1} language={language} />
                <SponsorsPackage tier={2} language={language} />
                <SponsorsPackage tier={3} language={language} />
                <SponsorsPackage tier={4} language={language} />
              </div>
            </div>

            <div className="sponsors-page-services-container">
              <p className="sponsors-page-services-container-header">
                {isEnglish ? "Advertising Services" : "Services publicitaires"}
              </p>
              <div className="sponsors-page-card-wrapper">
                <SponsorsCard tier={1} language={language} />
                <SponsorsCard tier={2} language={language} />
                <SponsorsCard tier={3} language={language} />
              </div>
            </div>
            {
              this.getSponsoringForm(isEnglish)
            }
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default SponsorsPage;
