import React, { useState, useEffect } from 'react';
import { Modal, Card, Row, Col } from 'react-bootstrap';
import "../../scss/book-shelf.scss";

const BookShelf = ({show, onClose, availableBooks, bookOnSelect}) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      className="book-shelf-model"
    >
      <Modal.Header closeButton>
        <Modal.Title>Available E-Books</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {
            availableBooks.map(ebook => (
              <Col className="col-sm-6 col-md-3" key={ebook.id} >
                <Card 
                  className="book-card-container"
                  onClick={() => bookOnSelect(ebook.id)}
                >
                  <Card.Img variant="top" src={ebook.coverImageUrl} />
                  <Card.Body>
                    <Card.Title className="book-title">{ebook.displayName}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))
          }
        </Row>
      </Modal.Body>
    </Modal>
  )
};

export default BookShelf;


