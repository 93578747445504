import React from 'react';
import '../../scss/shopping_page/order-popup.scss';

import Modal from 'react-responsive-modal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const OrderPopup = ({ 
  open, 
  onClose,
  previewImages, 
  title, 
  description, 
  pricingComponent,
  shippingMessage
}) => (
  <Modal open={open} onClose={onClose} styles={{modal: {margin: "2em auto"} }} center>
    <div id="order-popup-container">
      <Carousel showArrows={true} infiniteLoop={true} showIndicators={false} dynamicHeight={true} showStatus={false}>
        {
          previewImages.map(imageSrc => (
            <div key={imageSrc}>
              <img src={imageSrc} alt="demo"></img>
            </div>
          ))
        }
      </Carousel>
      <p className="popup-title">{title}</p>
      <p className="popup-description">{description}</p>
      <p className="popup-pricing">
        {pricingComponent}
      </p>
      <p className="popup-shipping-message">{shippingMessage}</p>
    </div>
  </Modal>
)

export default OrderPopup;