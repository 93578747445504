import React from 'react';
import '../scss/footer.scss';
import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import AmyFoodVlogs from '../img/AmyFoodVlogs.jpg';
import DesjardinsLogo from '../img/desjardins_logo.png';


class Footer extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(prop) {
    super(prop);
  }

  state = {
    ourPartners: ' ',
    pages: ' ',
    home: ' ',
    services: ' ',
    tools: ' ',
    company: ' ',
    about: ' ',
    sponsors: ' ',
    contactUs: ' ',
    links: ' ',
    makeADonation: ' ',
  }
  componentWillReceiveProps(nextProp) {
    let isEnglish = true;
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('language')) {
      if (searchParams.get('language') == 'en') {
        isEnglish = true;
      } else {
        isEnglish = false;
      }
    } else {
      window.location.search = 'language=en';
    }
    if (isEnglish) {
      this.setState({
        ourPartners: 'OUR PARTNERS',
        partners: 'Partners',
        becomeOurPartners: 'Become Our Partners',
        pages: 'Pages',
        home: 'Home',
        services: 'Services',
        tools: 'Tools',
        company: 'Company',
        about: 'About',
        sponsors: 'Sponsors',
        contactUs: 'Contact Us',
        links: 'Links',
        makeADonation: 'Make A Donation',
      });
    } else {
      this.setState({
        ourPartners: 'NOS PARTENAIRES',
        partners: 'Partenaires',
        becomeOurPartners: 'Devenir Nos Partenaires',
        pages: 'Pages',
        home: 'Accueil',
        services: 'Services',
        tools: 'Outils',
        company: 'Entreprise',
        about: 'À Propos',
        sponsors: 'Commanditaires',
        contactUs: 'Contactez Nous',
        links: 'Liens',
        makeADonation: 'Faire Un Don',
      });
    }
  }

  render() {
    const { state } = this;

    return (
      <div className="footer_container">
        <div className="footer">
          {/* Partner logos go here */}
        </div>
        <div className='yellow_line_thin'></div>
        <div className='real_footer_container'>
          <div className='three_coloum_container'>
            <div className='coloum_of_links'>
              <h4><span>{state.pages}</span></h4>
              <h4><Link to='/#nav_bar'>{state.home}</Link></h4>
              <h4><Link to='/services#nav_bar'>{state.services}</Link></h4>
              <h4><Link to='/tools#nav_bar'>{state.tools}</Link></h4>
            </div>
            <div className='coloum_of_links'>
              <h4><span>{state.company}</span></h4>
              <h4><Link to='/about#nav_bar'>{state.about}</Link></h4>
              <h4><Link to='/sponsors#nav_bar'>{state.sponsors}</Link></h4>
              <h4><Link to='/contact#nav_bar'>{state.contactUs}</Link></h4>
            </div>
            <div className='coloum_of_links'>
              <h4><span>{state.links}</span></h4>
              <h4><a href='https://donorbox.org/boxia-partage-donation?hide_donation_meter=true' target="_blank">{state.makeADonation}</a></h4>
            </div>
          </div>
          <div className='grey_line'></div>
          <div className='links_bottom_row'>
            <h2 id='boxia-text'>Boxia</h2>
            <div className="links_container">
              <a href="mailto:irene@boxia.ca" className="link_container">
                <FontAwesomeIcon icon={faEnvelope} />
                <h4>Email</h4>
              </a>
              <a href="https://www.linkedin.com/in/ixiazhou" className="link_container">
                <FontAwesomeIcon icon={faLinkedinIn} />
                <h4>LinkedIn</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
